<template>
  <runai-expansion-item label="Volume" :subheader="summary" :section-invalid="sectionInvalid" aid="volume-section">
    <q-linear-progress animation-speed="100" indeterminate v-if="loading" />

    <template v-else>
      <div class="row items-center q-mb-md">
        <span class="q-mr-md">Set the volume needed for your workload</span>
        <runai-tooltip
          tooltip-text="A volume allocates storage space to your workload that is persistent across restarts."
        />
      </div>
      <volume-box
        v-for="volume in volumes"
        :key="volume.claimName"
        :volume="volume"
        :storage-classes="storageClasses"
        @update-volume="updateVolume"
        @remove-volume="removeVolume"
        :disable="sectionDisabled"
        :policy-rules="policyRules.attributes"
      />
      <q-btn
        :disable="disableAddButton"
        v-if="!volumes.length"
        aid="add-volume-btn"
        label="+ volume"
        flat
        color="primary"
        @click="addVolume"
      />
    </template>
  </runai-expansion-item>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
// Components
import { RunaiExpansionItem } from "@/components/common/runai-expansion-item";
import { RunaiTooltip } from "@/components/common/runai-tooltip";
import { VolumeBox } from "./volume-box";
// Models
import type { IUIVolume } from "@/models/data-source.model";
import type { PvcAccessModes } from "@/swagger-models/assets-service-client";
// Utils
import { dataSourceUtil } from "@/utils/data-source.util";
import { PvcsRules } from "@/swagger-models/policy-service-client";

export default defineComponent({
  name: "volume-section",
  components: { RunaiExpansionItem, RunaiTooltip, VolumeBox },
  emits: ["add-volume", "remove-volume", "update-volumes"],
  props: {
    volumes: {
      type: Array as PropType<Array<IUIVolume>>,
      required: true,
    },
    storageClasses: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
    loading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    sectionDisabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    policyRules: {
      type: Object as PropType<PvcsRules>,
      required: false,
      default: () => ({}),
    },
  },
  computed: {
    disableAddButton(): boolean {
      return this.sectionDisabled || this.policyRules.instances?.canAdd === false;
    },
    summary(): string {
      if (this.volumes[0]) {
        return this.volumes[0].ephemeral ? "Ephemeral" : "Persistent";
      } else {
        return "None";
      }
    },
    sectionInvalid(): boolean {
      if (!this.volumes.length) {
        return false;
      } else {
        return this.volumes.some((volume: IUIVolume) => !this.isVolumeValid(volume));
      }
    },
  },
  methods: {
    isVolumeValid(volume: IUIVolume): boolean {
      const { size, storageClass, accessModes } = volume.claimInfo;
      return !!size && !!storageClass && !!volume.path && this.validAccessModes(accessModes || {});
    },
    validAccessModes(accessModes: PvcAccessModes): boolean {
      return Object.values(accessModes).some((mode: boolean) => mode);
    },
    addVolume(): void {
      this.$emit("update-volumes", [...this.volumes, dataSourceUtil.getEmptyVolume()]);
    },
    updateVolume(volume: IUIVolume): void {
      this.$emit(
        "update-volumes",
        this.volumes.map((v: IUIVolume) => (v.claimName === volume.claimName ? volume : v)),
      );
    },
    removeVolume(claimName: string): void {
      this.$emit(
        "update-volumes",
        this.volumes.filter((v: IUIVolume) => v.claimName !== claimName),
      );
    },
  },
});
</script>
