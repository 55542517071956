<template>
  <runai-tooltip-wrapper
    :display-tooltip="disableInput"
    :tooltip-text="disabledTooltipText"
    anchor="bottom middle"
    self="bottom middle"
  >
    <q-input
      type="number"
      class="policy-number-input"
      :aid="aid"
      :min="min"
      :max="max"
      :step="step"
      :class="elementClasses"
      :style="inlineStyle"
      :model-value="modelValue"
      :disable="disableInput"
      :stack-label="stackLabel"
      :placeholder="placeholder"
      :label="label"
      :input-class="inputClass"
      :rules="rulesWithPolicy"
      :ref="customRef"
      no-error-icon
      @update:model-value="updateValue"
    />
    <q-tooltip v-if="showExplanatoryTooltip" max-width="300px">{{ tooltip }}</q-tooltip>
  </runai-tooltip-wrapper>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import type { NumberRules } from "@/swagger-models/policy-service-client";
import { RunaiTooltipWrapper } from "@/components/common/runai-tooltip-wrapper";
import type { ValidationRule } from "quasar";
import { notLargerThanRule, notSmallerThanRule, validStepRule } from "@/common/form.validators";
import { errorMessages } from "@/common/error-message.constant";
import { isNullOrUndefined } from "@/utils/common.util";

export default defineComponent({
  name: "policy-number-input",
  components: {
    RunaiTooltipWrapper,
  },
  props: {
    modelValue: {
      type: [Number, null, undefined] as PropType<number | null | undefined>,
      required: true,
    },
    policyRules: {
      type: Object as PropType<NumberRules>,
      required: false,
    },
    label: {
      type: String as PropType<string>,
      required: false,
    },
    tooltip: {
      type: String as PropType<string>,
      required: false,
    },
    disable: {
      type: Boolean as PropType<boolean>,
      required: false,
    },
    placeholder: {
      type: String as PropType<string>,
      required: false,
    },
    stackLabel: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    maxValue: {
      type: Number as PropType<number>,
      required: false,
    },
    minValue: {
      type: Number as PropType<number>,
      required: false,
    },
    inputClass: {
      type: String as PropType<string>,
      required: false,
    },
    inlineStyle: {
      type: Object as PropType<Record<string, string>>,
      required: false,
    },
    elementClasses: {
      type: String as PropType<string>,
      required: false,
    },
    dense: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    hint: {
      type: String as PropType<string>,
      required: false,
    },
    rules: {
      type: Array as PropType<ValidationRule[]>,
      required: false,
    },
    aid: {
      type: String as PropType<string>,
      required: false,
    },
    customRef: {
      type: String as PropType<string>,
      required: false,
    },
  },
  computed: {
    disableInput(): boolean {
      if (this.policyRules?.canEdit === false) return true;
      return !!this.disable;
    },
    disabledTooltipText(): string {
      if (this.policyRules?.canEdit === false) {
        return errorMessages.CANT_BE_MODIFIED_POLICY;
      }
      return "";
    },
    max(): number | undefined {
      //TODO: should policy overrwrite our application max? whichever is larger? verify with product
      return this.policyRules?.max || this.maxValue;
    },
    min(): number | undefined {
      //TODO: should policy overrwrite our application min? whichever is smaller? verify with product
      return this.policyRules?.min || this.minValue;
    },
    step(): number | undefined {
      return this.policyRules?.step || undefined;
    },
    rulesWithPolicy(): ValidationRule[] {
      if (!this.policyRules) return this.rules || [];

      const rules = this.rules || [];
      this.policyRules.required && rules.push(this.requiredRule);
      this.policyRules.max && rules.push(this.maxRule);
      this.policyRules.min && rules.push(this.minRule);
      this.policyRules.step && rules.push(this.stepRule);

      return rules;
    },
    showExplanatoryTooltip(): boolean {
      if (this.disableInput) return false;
      return !!this.tooltip;
    },
  },
  methods: {
    requiredRule(val: number): boolean | string {
      return !isNullOrUndefined(val) || errorMessages.REQUIRED_FIELD;
    },
    maxRule(val: number): boolean | string {
      const max = this.max || Infinity;
      return notLargerThanRule(val, max);
    },
    minRule(val: number): boolean | string {
      const min = this.min || 0;
      return notSmallerThanRule(val, min);
    },
    stepRule(val: number): boolean | string {
      const min = this.min || 0;
      const step = this.step || 1;
      return validStepRule(val, min, step);
    },
    updateValue(val: string | number | null): void {
      this.$emit("update:model-value", val);
    },
  },
});
</script>
