<template>
  <stat-widget-wrapper
    :options="wrapperOptions"
    @link-clicked="redirectToNodes"
    :loading="displayLoading"
    :error="error"
  >
    <template #data> {{ statData }}</template>
  </stat-widget-wrapper>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";
//routes
import { NODE_ROUTE_NAMES } from "@/router/node.routes/node.routes.names";
//store
import { useClusterStore } from "@/stores/cluster.store";
//models
import { EWidgetEntity, type IWidgetWrapperOptions } from "@/models/chart.model";
import { HttpErrorResponse } from "@/models/http-response.model";
import type { IFilterBy } from "@/models/filter.model";
import { ETableFilters } from "@/models/table.model";
import { allNodesColumns, ENodeColumnName } from "@/table-models/node.table-model";
//cmps
import { StatWidgetWrapper } from "@/components/dashboard-v2/widgets/common/widget-wrapper/stat-widget-wrapper";
//svc
import { filterService } from "@/services/filter.service/filter.service";
import { clusterService } from "@/services/control-plane/cluster.service/cluster.service";
import { NodeTelemetryType } from "@/swagger-models/cluster-service-client";
//util
import { widgetUtil } from "@/utils/widget.util";
import { MIN_CLUSTER_TELEMETRY_TYPE_VERSION } from "@/common/version.constant";
import { formatUtil } from "@/utils/format.util/format.util";

export default defineComponent({
  name: "ready-nodes-widget",
  components: { StatWidgetWrapper },
  props: {
    clusterId: {
      type: String as PropType<string>,
      required: true,
    },
    nodePoolName: {
      type: String as PropType<string>,
      required: false,
    },
  },
  data() {
    return {
      readyNodes: 0 as number,
      totalNodes: 0 as number,
      error: false as boolean,
      displayLoading: true as boolean,
      clusterStore: useClusterStore(),
    };
  },
  created() {
    this.loadData();
  },
  computed: {
    statData(): string | number {
      const format = (val: number) => formatUtil.numberFormat(val, true);
      if (this.isClusterTelemetrySupported) {
        return `${format(this.readyNodes)}/${format(this.totalNodes)}`;
      }
      return format(this.totalNodes);
    },
    wrapperOptions(): IWidgetWrapperOptions {
      const title = this.isClusterTelemetrySupported ? "Ready / Total GPU nodes" : "Total GPU nodes";
      return {
        timeFrame: "Now",
        linkText: "All nodes",
        title: title,
        entityType: EWidgetEntity.Node,
        tooltipText:
          "Ready: The number of GPU nodes that are available for scheduling </br>Total: The number of GPU nodes in the cluster",
      };
    },
    isClusterTelemetrySupported(): boolean {
      return this.clusterStore.isClusterVersionSufficient(this.clusterId, MIN_CLUSTER_TELEMETRY_TYPE_VERSION);
    },
  },
  methods: {
    async loadData(): Promise<void> {
      try {
        if (this.isClusterTelemetrySupported) {
          await Promise.all([
            this.loadNodesTelemetry(NodeTelemetryType.TotalGpuNodes),
            this.loadNodesTelemetry(NodeTelemetryType.ReadyGpuNodes),
          ]);
        } else {
          await this.loadNodesTelemetry(NodeTelemetryType.TotalGpuNodes);
        }
        this.error = false;
      } catch (error: unknown) {
        this.handleError(error);
      } finally {
        this.displayLoading = false;
      }
    },
    async loadNodesTelemetry(telemetryType: NodeTelemetryType): Promise<void> {
      const response = await clusterService.getNodeTelemetry(telemetryType, this.clusterId, this.nodePoolName);
      const values = widgetUtil.sumAllTelemetryValues(response.values);
      if (telemetryType === NodeTelemetryType.TotalGpuNodes) {
        this.totalNodes = values;
      } else {
        this.readyNodes = values;
      }
    },
    handleError(error: unknown): void {
      this.error = true;
      if (error instanceof HttpErrorResponse) {
        console.error(error.message);
      } else {
        console.error("Error fetching data", error);
      }
    },
    redirectToNodes(): void {
      if (this.isClusterTelemetrySupported) {
        this.setColumnFilter();
      }
      this.$router.push({ name: NODE_ROUTE_NAMES.NODE_INDEX });
    },
    getNodesFilterBy(): IFilterBy {
      const defaultFilters: IFilterBy = filterService.getDefaultFilters(ENodeColumnName.Status, allNodesColumns);
      return filterService.loadFilters(window.location, ETableFilters.NODE, defaultFilters);
    },
    setColumnFilter(): void {
      const nodesFilterBy: IFilterBy = this.getNodesFilterBy();

      filterService.setColumnFilter(nodesFilterBy, "Ready", ENodeColumnName.Status, ETableFilters.NODE);
      if (this.nodePoolName) {
        this.setNodePoolColumnFilter(nodesFilterBy);
      } else {
        this.removeNodePoolFilter(nodesFilterBy);
      }
    },
    setNodePoolColumnFilter(nodesFilterBy: IFilterBy): void {
      filterService.setColumnFilter(
        nodesFilterBy,
        this.nodePoolName as string,
        ENodeColumnName.NodePool,
        ETableFilters.NODE,
      );
    },
    removeNodePoolFilter(nodesFilterBy: IFilterBy): void {
      filterService.removeColumnFilter(nodesFilterBy, ENodeColumnName.NodePool, ETableFilters.NODE);
    },
  },
  watch: {
    nodePoolName: {
      handler(): void {
        this.displayLoading = true;
        this.loadData();
      },
    },
    clusterId: {
      handler(): void {
        this.displayLoading = true;
        this.loadData();
      },
    },
  },
});
</script>

<style scoped lang="scss"></style>
