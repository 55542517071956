<template>
  <runai-form-wrapper :form-state="formData" ref="elProjectEditCreateForm">
    <runai-expansion-wrapper>
      <project-scope-section
        @update-scope="updateProjectScope"
        :cluster-id="formData.clusterId"
        :department-id="formData.parentId"
      />
      <project-name-section
        v-model:project-name="formData.name"
        v-model:description="formData.description"
        :namespace="namespace"
        :cluster-id="formData.clusterId"
        @is-section-invalid="invalidSection.projectName = !$event"
      />
      <quota-management-section
        v-if="formData.parentId"
        v-model:resources="formData.resources"
        v-model:node-pools-priorities="formData.defaultNodePools"
        :loading="loading"
        :entity="EQuotaEntity.project"
        :department-id="formData.parentId"
        :cluster-id="formData.clusterId"
        :quota-statuses="quotaStatuses"
      />
      <scheduling-rules-section
        :cluster-id="formData.clusterId"
        v-model:scheduling-rules="formData.schedulingRules"
        v-model:node-types="formData.nodeTypes"
        @is-section-invalid="invalidSection.schedulingRules = !$event"
      />
      <project-form-footer-section
        :loading="isProjectSubmitting"
        :is-form-valid="isFormValid"
        @on-cancel="$emit('on-cancel')"
        @on-save="save"
      />
    </runai-expansion-wrapper>
  </runai-form-wrapper>
</template>

<script lang="ts">
import { computed, type PropType } from "vue";
import { defineComponent } from "vue";
// cmps
import { RunaiExpansionWrapper } from "@/components/common/runai-expansion-wrapper";
import { RunaiFormWrapper } from "@/components/common/runai-form-wrapper";
import { SchedulingRulesSection } from "@/components/project/project-edit-form/scheduling-rules-section/";
import { ProjectNameSection } from "@/components/project/project-edit-form/project-name-section";
import { QuotaManagementSection } from "@/components/project/project-edit-form/quota-management-section";
import { ProjectFormFooterSection } from "@/components/project/project-edit-form/project-form-footer-section";
// services
import { deepCopy } from "@/utils/common.util";
// stores
import { useSettingStore } from "@/stores/setting.store";
// models
import { EQuotaEntity } from "@/models/resource.model";
import type { INodePoolsNameAndId } from "@/models/node-pool.model";
import ProjectScopeSection from "@/components/project/project-edit-form/project-scope-section/project-scope-section.vue";
import { ErrorAlert } from "@/utils/error-alert.util";
import {
  type AggregatedResources,
  type Project,
  type ProjectCreationRequest,
  type QuotaStatusNodePool,
  type Resources,
  type SchedulingRules,
} from "@/swagger-models/org-unit-service-client";
import { orgUnitService } from "@/services/control-plane/org-unit.service/org-unit.service";
import { orgUnitUtil } from "@/utils/org-unit.util";
import type { Department } from "@/swagger-models/org-unit-service-client";

interface ISectionValidation {
  departmentSelect: boolean;
  projectName: boolean;
  schedulingRules: boolean;
}
export default defineComponent({
  components: {
    ProjectScopeSection,
    SchedulingRulesSection,
    ProjectFormFooterSection,
    ProjectNameSection,
    QuotaManagementSection,
    RunaiFormWrapper,
    RunaiExpansionWrapper,
  },
  emits: ["on-save", "on-cancel"],
  props: {
    project: {
      type: Object as PropType<Project | ProjectCreationRequest>,
      required: true,
    },
    isProjectSubmitting: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  provide() {
    return {
      projectsAggregatedResources: computed<AggregatedResources[]>(
        () => this.department?.projectsAggregatedResources || [],
      ),
      departmentResources: computed<Resources[]>(() => this.department?.resources || []),
    };
  },
  data() {
    return {
      formData: deepCopy(this.project) as Project | ProjectCreationRequest,
      settingStore: useSettingStore(),
      isFormValid: true as boolean,
      loading: false as boolean,
      department: null as Department | null,
      invalidSection: {
        departmentSelect: false as boolean,
        projectName: false as boolean,
        schedulingRules: false as boolean,
      } as ISectionValidation,
    };
  },
  async created() {
    if (this.formData.parentId) {
      await this.loadDepartment(this.formData.parentId);
    }
    if (!this.isDepartmentEnabled) {
      this.invalidSection.departmentSelect = true;
    }
  },
  computed: {
    schedulingRules(): SchedulingRules {
      return (
        this.formData.schedulingRules || {
          trainingJobMaxIdleDurationSeconds: null,
          interactiveJobMaxIdleDurationSeconds: null,
          interactiveJobPreemptIdleDurationSeconds: null,
          interactiveJobTimeLimitSeconds: null,
          trainingJobTimeLimitSeconds: null,
        }
      );
    },
    namespace(): string {
      if (this.formData.requestedNamespace) {
        return this.formData.requestedNamespace;
      } else {
        return (this.formData as Project).status?.namespace || "";
      }
    },
    quotaStatuses(): Array<QuotaStatusNodePool> {
      return (this.project as Project)?.status?.nodePoolQuotaStatuses || [];
    },
    EQuotaEntity(): typeof EQuotaEntity {
      return EQuotaEntity;
    },
    isDepartmentEnabled(): boolean {
      return this.settingStore.isDepartmentEnabled;
    },
    isCpuEnabled(): boolean {
      return this.settingStore.isCPUResourcesQuotaEnabled;
    },
  },
  methods: {
    async updateProjectScope({
      clusterId,
      departmentId,
    }: {
      clusterId: string;
      departmentId: string | null;
    }): Promise<void> {
      this.loading = true;
      try {
        this.formData.clusterId = clusterId;
        await this.updateDepartmentId(departmentId, clusterId);
        this.updateProjectNodePools();
      } catch (error: unknown) {
        this.handleUpdateScopeError(error);
      } finally {
        this.loading = false;
      }
    },
    handleUpdateScopeError(error: unknown): void {
      const errorAlert = new ErrorAlert({
        generalMessage: `Failed to update project scope`,
      });
      this.$q.notify(errorAlert.getNotification(error));
    },
    async updateDepartmentId(departmentId: string | null, clusterId: string): Promise<void> {
      if (departmentId !== null) {
        this.formData.parentId = departmentId;
        await this.loadDepartment(departmentId);
      } else {
        await this.loadDefaultDepartment(clusterId);
      }
    },
    async getDefaultDepartment(clusterId: string): Promise<Department> {
      const defaultDepartment = await orgUnitService.getDefaultDepartment(clusterId);
      if (defaultDepartment === null) {
        throw new Error("No default department found");
      }
      return defaultDepartment;
    },
    async loadDefaultDepartment(clusterId: string): Promise<void> {
      const defaultDepartment = await this.getDefaultDepartment(clusterId);
      this.formData.parentId = defaultDepartment.id;
      this.department = defaultDepartment;
    },
    async loadDepartment(departmentId: string): Promise<void> {
      this.department = await orgUnitService.getDepartment(departmentId);
    },
    updateProjectNodePools(): void {
      if (this.department) {
        this.formData.resources = orgUnitUtil.getEmptyResourcesModel(
          this.getNodePoolsNamesAndIds(this.department.resources),
          this.isCpuEnabled,
        );
      }
    },
    getNodePoolsNamesAndIds(resources: Resources[]): INodePoolsNameAndId[] {
      return resources.map((resources) => {
        return {
          id: orgUnitUtil.getNodePoolIdByResource(resources),
          name: orgUnitUtil.getNodePoolNameByResource(resources),
        };
      });
    },
    async validate(): Promise<boolean> {
      return await (this.$refs.elProjectEditCreateForm as HTMLFormElement).validate();
    },
    async save(): Promise<void> {
      this.isFormValid = await this.validate();
      if (this.isFormValid) {
        this.$emit("on-save", this.formData);
      }
    },
  },
  watch: {
    formData: {
      handler() {
        this.isFormValid = true;
      },
      deep: true,
    },
  },
});
</script>
