import { defineStore } from "pinia";
import { clusterService } from "@/services/control-plane/cluster.service/cluster.service";
import { clusterApiService } from "@/services/cluster/cluster-api.service/cluster-api.service";
import { allClusterColumns } from "@/table-models/cluster.table-model";
import { filterService } from "@/services/filter.service/filter.service";
import { TRAINING_DISTRIBUTED_MIN_VERSION, type ICluster } from "@/models/cluster.model";
import {
  ALL_CLUSTER_FILTER_VALUE,
  CLUSTER_COLUMN_FILTER_NAME,
  type IFilterBy,
  type IFilterModel,
} from "@/models/filter.model";
import { useAuthStore } from "@/stores/auth.store";
import { useGrafanaStore } from "./grafana.store";
import { isNewerVersion } from "@/utils/version.util";
import { usePermissionStore } from "@/stores/permissions.store";
import { ResourceType, Action } from "@/swagger-models/authorization-client";
import { ClusterDisplayedStatusStateEnum } from "@/swagger-models/cluster-service-client";

import { MIN_VERSION_BACKOFF_LIMIT } from "@/common/version.constant";
import type { DisplayedCluster } from "@/swagger-models/cluster-service-client";
import type { ETableFilters } from "@/models/table.model";

export const useClusterStore = defineStore("Cluster", {
  state: () => ({
    clusters: [] as Array<ICluster>,
    currentCluster: {} as DisplayedCluster,
    lastCreatedClusterUuid: null as null | string,
    firstConnectedCluster: undefined as ICluster | undefined,
    selectedCluster: null as DisplayedCluster | null,
  }),
  getters: {
    clusterList(): Array<ICluster> {
      return this.clusters;
    },
    clusterListLastCreatedExcluded(): Array<ICluster> {
      if (!this.lastCreatedClusterUuid) return this.clusters;
      return this.clusters.filter((cluster: ICluster) => cluster.uuid !== this.lastCreatedClusterUuid);
    },
    lastCreatedCluster(): ICluster | null {
      if (!this.lastCreatedClusterUuid) return null;
      return this.clusters.find((cluster: ICluster) => cluster.uuid === this.lastCreatedClusterUuid) || null;
    },
    isClusterAmdGpuType:
      (state) =>
      (clusterId: string): boolean => {
        const cluster = state.clusters.find((cluster: ICluster) => cluster.uuid === clusterId);
        return cluster?.status?.dependencies?.required?.["gpu-stack"].components?.amd?.available || false;
      },
    availableDependenciesByClusterUuid:
      (state) =>
      (clusterUuid: string): Array<string> => {
        const relevantCluster = state.clusters.find((c) => c.uuid === clusterUuid);
        if (!relevantCluster) return [];
        const requiredDependenciesAvailable: Array<string> = Object.keys(
          relevantCluster.status?.dependencies?.required || {},
        ).filter((key: string) => relevantCluster.status?.dependencies?.required[key]?.available);

        const optionalDependenciesAvailable: Array<string> = Object.keys(
          relevantCluster.status?.dependencies?.optional || {},
        ).filter((key: string) => relevantCluster.status?.dependencies?.optional[key]?.available);

        return [...requiredDependenciesAvailable, ...optionalDependenciesAvailable];
      },
    isVersionSupportDistributedTraining:
      (state) =>
      (clusterUuid: string): boolean => {
        const clusterVersion = state.clusters.find((cluster: ICluster) => clusterUuid === cluster.uuid)?.version || "";
        return isNewerVersion(clusterVersion, TRAINING_DISTRIBUTED_MIN_VERSION);
      },
    isClusterVersionSupportBackoffLimit:
      (state) =>
      (clusterUuid: string): boolean => {
        const clusterVersion = state.clusters.find((cluster: ICluster) => clusterUuid === cluster.uuid)?.version || "";
        return isNewerVersion(clusterVersion, MIN_VERSION_BACKOFF_LIMIT);
      },
    canCreateCluster(): boolean {
      const permissionStore = usePermissionStore();
      const authStore = useAuthStore();
      return permissionStore.hasPermission(ResourceType.Cluster, Action.Read) || authStore.isAdmin;
    },
    clusterByUuid:
      (state) =>
      (clusterUuid: string): ICluster | undefined => {
        return state.clusters.find((cluster: ICluster) => clusterUuid === cluster.uuid);
      },
    clusterNameByUuid:
      (state) =>
      (clusterUuid: string): string | undefined => {
        return state.clusters.find((cluster: ICluster) => clusterUuid === cluster.uuid)?.name;
      },
  },
  actions: {
    async loadClusters(filterBy: IFilterBy = {}): Promise<Array<ICluster>> {
      const clusterList = await clusterService.list();
      this.clusters = filterService.filterListByTableFilters(clusterList, filterBy, allClusterColumns);
      return this.clusters;
    },
    async create(name: string): Promise<ICluster> {
      const newCluster: ICluster = await clusterService.create(name);
      await useAuthStore().loadUserOrgUnits();
      this.lastCreatedClusterUuid = newCluster?.uuid || null;
      return newCluster;
    },
    async deleteCluster(uuid: string): Promise<void> {
      await clusterService.remove(uuid);
      this.clusters = this.clusters.filter((cluster: ICluster) => cluster.uuid !== uuid);
      await useAuthStore().loadUserOrgUnits();
    },
    async updateCluster(uuid: string, updatedCluster: ICluster): Promise<void> {
      await clusterService.update(uuid, updatedCluster);
    },
    removeLastCreatedCluster(): void {
      this.lastCreatedClusterUuid = null;
    },
    isClusterNameExists(name: string): boolean {
      return !!this.clusters.find((cluster) => cluster.name === name);
    },
    getClusterById(clusterId: string): ICluster | null {
      return this.clusters.find((cluster: ICluster) => cluster.uuid === clusterId) || null;
    },
    getClusterVersion(clusterId: string): string | null {
      const cluster: ICluster | null = this.getClusterById(clusterId);
      return cluster?.version || null;
    },
    isClusterVersionSufficient(clusterUuid: string | null, checkForVersion: string): boolean {
      if (!clusterUuid) return false;
      const clusterVersion = this.clusterList.find((c) => c.uuid === clusterUuid)?.version;
      if (!clusterVersion) return false;

      return isNewerVersion(clusterVersion, checkForVersion);
    },
    isAtleastOneClusterSufficientForVersion(checkForVersion: string): boolean {
      return this.clusterList.some((c) => isNewerVersion(c.version, checkForVersion));
    },
    isAllClustersSupportSpecificVersion(version: string): boolean {
      if (!version) return false;
      return this.clusterList.every((c: ICluster) => this.isClusterVersionSufficient(c.uuid, version));
    },
    /**
     * Retrieves the ID of the selected cluster based on the specified table filter.
     *
     * @param {ETableFilters} tableFilterName - The name of the table filter to apply.
     * @param isAdvancedFilter  isAdvancedFilter - A flag indicating whether the filter is an advanced filter and the cluster id should extract from the clusterId field.
     * @param supportsAllClusters - A flag indicating whether the table supports all clusters filter.
     * @returns {string} The ID of the filtered cluster if found; otherwise, the current cluster ID.
     */
    getClusterIdFromFilters(
      tableFilterName: ETableFilters,
      isAdvancedFilter = false,
      supportsAllClusters = false,
    ): string {
      const filterBy: IFilterBy = filterService.loadFilters(window.location, tableFilterName, {});
      const defaultClusterUuid = this.clusterList[0].uuid;
      let filteredClusterUuid: string | undefined;
      if (isAdvancedFilter) {
        filteredClusterUuid = filterBy.clusterId;
      } else {
        filteredClusterUuid = filterBy.columnFilters?.find(
          (filter: IFilterModel) => filter.name === CLUSTER_COLUMN_FILTER_NAME,
        )?.term;
      }

      if (supportsAllClusters && filteredClusterUuid === ALL_CLUSTER_FILTER_VALUE) {
        return "";
      }

      if (filteredClusterUuid && this.clusterList.some((cluster: ICluster) => cluster.uuid === filteredClusterUuid)) {
        return filteredClusterUuid;
      }

      if (defaultClusterUuid) {
        return defaultClusterUuid;
      }

      throw new Error("No cluster found for the specified filter criteria.");
    },
    isClusterDisconnectedById(clusterId: string): boolean {
      const cluster = this.clusterList.find((c) => c.uuid === clusterId);
      return cluster?.status?.state === ClusterDisplayedStatusStateEnum.Disconnected;
    },

    async setSelectedCluster(selectedCluster: DisplayedCluster | null): Promise<void> {
      this.selectedCluster = selectedCluster;

      if (!this.selectedCluster) {
        clusterApiService.setBaseURL("");
        return;
      }

      try {
        // TODO: do we really need this logic here? or move it to the relevant services
        clusterApiService.setBaseURL(this.selectedCluster.domain || "");

        // TODO: do we really need this logic here? or move it to dashboard pages
        if (usePermissionStore().hasPermission(ResourceType.DashboardsOverview, Action.Read)) {
          await useGrafanaStore().getGrafanaDashboards(this.selectedCluster.uuid);
        }
      } catch (e) {
        console.error("Error while setting selected cluster", e);
      }
    },

    async setSelectedClusterById(clusterId: string): Promise<void> {
      const cluster = this.clusterList.find((c) => c.uuid === clusterId);
      if (cluster) {
        await this.setSelectedCluster(cluster);
      } else {
        await this.setSelectedCluster(null);
      }
    },
  },
});
