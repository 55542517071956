<template>
  <runai-wrapper-with-header class="volume-box" header="Volume" @close="$emit('remove-volume', volume.claimName)">
    <template #body>
      <section class="q-pa-md" v-if="volume.claimInfo">
        <section class="row q-gutter-lg claim-info-section">
          <runai-select
            aid="storage-class-select"
            class="storage-class-select col-4"
            standard
            :model-value="storageClass"
            :options="storageClassOptions"
            label="Storage class"
            @update:model-value="storageClassChanged"
            :rules="[isStorageClassSelected]"
            :disable="disable"
            emit-value
            no-option-text="No storage classes found"
            :policy-rules="storageClassPolicy"
          />

          <q-select
            aid="access-mode-select"
            class="access-mode-select col-4"
            standard
            multiple
            counter
            max-values="3"
            label="Access mode"
            :model-value="getOptionsForMultiSelect"
            option-label="label"
            map-options
            lazy-rules
            :options="accessModeOptions"
            no-error-icon
            emit-value
            placeholder="Access mode"
            :rules="[isAccessModeSelected]"
            @update:model-value="accessModeChanged"
            :disable="disable"
          >
            <template #option="scope">
              <q-item v-bind="scope.itemProps" class="row justify-between items-center">
                <q-item-label>{{ scope.opt.label }}</q-item-label>
                <runai-tooltip class="q-ml-md" tooltip-position="right" :tooltip-text="scope.opt.toolTip" />
              </q-item>
            </template>
          </q-select>
        </section>

        <volume-size-section :model-value="volume.claimInfo.size" @update:model-value="sizeChanged" :disable="disable" />
        <div class="row q-gutter-lg q-mb-lg">
          <runai-select
            aid="volume-mode-select"
            class="volume-mode-select col-4"
            label="Volume mode"
            :options="volumeModeOptions"
            :model-value="volume.claimInfo.volumeMode || null"
            @update:model-value="volumeModeChanged"
            :disable="disable"
          />
        </div>

        <section class="container-path-section">
          <div class="q-py-md q-mt-md">Set the volume target location</div>
          <policy-string-field
            aid="path-input"
            :model-value="volume.path"
            no-error-icon
            label="Container path"
            stack-label
            placeholder="e.g: /home/local/data"
            input-class="placeholder-italic"
            :rules="[notEmpty, isDirectoryPath]"
            @update:model-value="pathChanged"
            :disable="disable"
            :policy-rules="policyRules?.path"
          />
        </section>
        <section class="q-pt-lg">
          <div>Volume persistency</div>
          <runai-radio-options
            :model-value="!!volume.ephemeral"
            @update:model-value="volumePesistencyChanged"
            :options="volumePesistencyOptions"
            class="q-pt-sm column"
            :disable="disable"
          />
        </section>
      </section>
    </template>
  </runai-wrapper-with-header>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
// Components
import { RunaiSelect } from "@/components/common/runai-select";
import { RunaiWrapperWithHeader } from "@/components/common/runai-wrapper-with-header";
import { RunaiTooltip } from "@/components/common/runai-tooltip";
import { VolumeSizeSection } from "./volume-size-section";
import { RunaiRadioOptions } from "@/components/common/runai-radio-options";
import { PolicyStringField } from "@/components/common/policy-string-field";
// Models
import { ClaimInfoVolumeModeEnum, type ClaimInfo, type PvcAccessModes } from "@/swagger-models/assets-service-client";
import type { ISelectOption } from "@/models/global.model";
import { NONE_STORAGE_CLASS, type IUIVolume, NONE_STORAGE_CLASS_LABEL } from "@/models/data-source.model";
// Utils
import { isNotEmpty, isValidDirectoryPath } from "@/common/form.validators";
// Constants
import { errorMessages } from "@/common/error-message.constant";
import { PvcRules } from "@/swagger-models/policy-service-client";
import { IGenericSelectPolicyRules } from "@/models/policy.model";

type TAccessMode = "readWriteOnce" | "readOnlyMany" | "readWriteMany";

export default defineComponent({
  name: "volume-box",
  components: {
    RunaiWrapperWithHeader,
    RunaiTooltip,
    VolumeSizeSection,
    RunaiSelect,
    RunaiRadioOptions,
    PolicyStringField,
  },
  emits: ["update-volume", "remove-volume"],
  props: {
    volume: {
      type: Object as PropType<IUIVolume>,
      required: true,
    },
    storageClasses: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
    disable: {
      type: Boolean as PropType<boolean>,
      required: false,
    },
    policyRules: {
      type: [Object, null] as PropType<PvcRules | null>,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      accessModeOptions: [
        {
          label: "Read-write by one node",
          toolTip: "The volume can be mounted as read-write by a single node.",
          value: "readWriteOnce",
        },
        {
          label: "Read-only by many nodes",
          toolTip: "The volume can be mounted as read-only by many nodes.",
          value: "readOnlyMany",
        },
        {
          label: "Read-write by many nodes",
          toolTip: "The volume can be mounted as read-write by many nodes.",
          value: "readWriteMany",
        },
      ] as ISelectOption[],
      volumeModeOptions: [ClaimInfoVolumeModeEnum.Filesystem, ClaimInfoVolumeModeEnum.Block],
      volumePesistencyOptions: [
        {
          value: false,
          label: "Persistent",
          toolTip: "The volume and its data will be deleted only when the workload is deleted.",
        },
        {
          value: true,
          label: "Ephemeral",
          toolTip: `The volume and its data will be deleted every time the workload's status changes to "Stopped".`,
        },
      ] as ISelectOption[],
    };
  },
  computed: {
    getOptionsForMultiSelect(): TAccessMode[] {
      const options: TAccessMode[] = [];
      for (const key in this.volume.claimInfo.accessModes) {
        if (this.volume.claimInfo.accessModes[key as TAccessMode]) options.push(key as TAccessMode);
      }
      return options;
    },
    storageClass(): string | null {
      return this.volume.claimInfo.storageClass === NONE_STORAGE_CLASS
        ? NONE_STORAGE_CLASS_LABEL
        : this.volume.claimInfo.storageClass || null;
    },
    storageClassOptions(): string[] | ISelectOption[] {
      return [...this.storageClasses, NONE_STORAGE_CLASS_LABEL];
    },
    storageClassPolicy(): IGenericSelectPolicyRules | undefined {
      return this.policyRules?.claimInfo?.storageClass
        ? { ...this.policyRules?.claimInfo?.storageClass, isClosedList: true }
        : undefined;
    },
  },
  methods: {
    volumePesistencyChanged(ephemeral: boolean): void {
      this.volumeChanged({ ...this.volume, ephemeral });
    },
    storageClassChanged(storageClass: string): void {
      if (storageClass === NONE_STORAGE_CLASS_LABEL) storageClass = NONE_STORAGE_CLASS;
      this.volumeChanged({ ...this.volume, claimInfo: { ...this.volume.claimInfo, storageClass } });
    },
    volumeModeChanged(volumeMode: ClaimInfoVolumeModeEnum): void {
      this.volumeChanged({ ...this.volume, claimInfo: { ...this.volume.claimInfo, volumeMode } });
    },
    pathChanged(path: string | number | null): void {
      this.volumeChanged({ ...this.volume, path: path as string });
    },
    sizeChanged(size: string): void {
      this.volumeChanged({ ...this.volume, claimInfo: { ...this.volume.claimInfo, size } });
    },
    accessModeChanged(accessModel: TAccessMode[]): void {
      const claimInfo: ClaimInfo = { ...this.volume.claimInfo, accessModes: this.readyAccessModesData(accessModel) };
      this.volumeChanged({ ...this.volume, claimInfo });
    },
    readyAccessModesData(val: TAccessMode[]): PvcAccessModes {
      if (!val.length) return {};
      return val.reduce((acc: PvcAccessModes, curr: TAccessMode) => {
        acc[curr] = true;
        return acc;
      }, {});
    },
    volumeChanged(volume: IUIVolume): void {
      this.$emit("update-volume", volume);
    },
    // Rules
    isStorageClassSelected(val: string): boolean | string {
      return isNotEmpty(val) || errorMessages.STORAGE_CLASS_NOT_EMPTY;
    },
    isAccessModeSelected(val: string): boolean | string {
      return isNotEmpty(val) || errorMessages.SELECT_A_MODE;
    },
    isDirectoryPath(val: string): boolean | string {
      return isValidDirectoryPath(val) || errorMessages.INVALID_DIRECTORY_PATH;
    },
    notEmpty(val: string): boolean | string {
      return isNotEmpty(val) || errorMessages.PATH_NOT_EMPTY;
    },
  },
});
</script>
