<template>
  <section class="tolerations-section">
    <div>Set toleration(s) to let the workload be scheduled on a node with a matching taint</div>
    <toleration-box
      v-for="item in displayedItems"
      :key="item.id"
      :toleration="item.origin"
      @remove-toleration="removeToleration(item.id)"
      @update-toleration="updateToleration(item.id, $event)"
      :read-only="item.isLocked || readOnly"
      :disabled-tooptip="item.isLocked ? disabledByPolicyText : undefined"
      :policy-rules="policyRules?.attributes"
    />
    <q-btn
      aid="add-toleration-btn"
      flat
      label="+ toleration"
      color="primary"
      class="q-mt-sm"
      @click="addToleration"
      :disable="cannotAddDueToPolicy || readOnly"
    >
      <q-tooltip v-if="cannotAddDueToPolicy">{{ disabledByPolicyText }}</q-tooltip></q-btn
    >
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
// Components
import { TolerationBox } from "./toleration-box";
// Models
import type { Toleration } from "@/swagger-models/assets-service-client";
import { useItemizedPolicy } from "@/composables/use-itemized-policy.composable";
import { ItemizedMapperEntities } from "@/composables/helpers/use-itemized-policy.mapper";
import { TolerationsDefaults, TolerationsRules } from "@/swagger-models/policy-service-client";

export default defineComponent({
  name: "tolerations-section",
  components: {
    TolerationBox,
  },
  emits: ["update-tolerations"],
  props: {
    tolerations: {
      type: Array as PropType<Array<Toleration>>,
      default: () => [],
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    policyRules: {
      type: Object as PropType<TolerationsRules>,
      required: false,
    },
    policyDefaults: {
      type: Object as PropType<TolerationsDefaults>,
      required: false,
    },
  },
  setup(props) {
    const { disabledByPolicyText, cannotAddDueToPolicy, addItem, removeItem, updateItem, displayedItems, fullList } =
      useItemizedPolicy(
        props.tolerations,
        ItemizedMapperEntities.Tolerations,
        props.policyRules?.instances,
        props.policyDefaults?.instances,
      );

    return {
      disabledByPolicyText,
      cannotAddDueToPolicy,
      addItem,
      removeItem,
      updateItem,
      displayedItems,
      fullList,
    };
  },
  methods: {
    getDefaultToleration(): Toleration {
      // rotemTODO: we can no longer do this as some of those might not be premitted by policy
      return {
        key: "",
        operator: "Exists",
        value: null,
        effect: "NoSchedule",
      };
    },
    addToleration() {
      this.addItem(this.getDefaultToleration());
      this.$emit("update-tolerations", this.fullList);
    },
    updateToleration(id: string, toleration: Toleration) {
      this.updateItem(id, toleration);
      this.$emit("update-tolerations", this.fullList);
    },
    removeToleration(id: string) {
      this.removeItem(id);
      this.$emit("update-tolerations", this.fullList);
    },
  },
});
</script>
