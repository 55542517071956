/* tslint:disable */
/* eslint-disable */
/**
 * K8sObjectTracker
 * An API for tracking k8s object in the cluster
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@run.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * an issue found while syncing the resource to the cluster
 * @export
 * @interface ClusterObjectIssue
 */
export interface ClusterObjectIssue {
    /**
     * the scope type of the resource.
     * @type {string}
     * @memberof ClusterObjectIssue
     */
    'scopeType': string;
    /**
     * The id of the cluster, department or project, depending on the scope type.
     * @type {string}
     * @memberof ClusterObjectIssue
     */
    'scopeId': string;
    /**
     * 
     * @type {ClusterObjectIssueType}
     * @memberof ClusterObjectIssue
     */
    'issue': ClusterObjectIssueType;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ClusterObjectIssueType = {
    ReplicationError: 'ReplicationError',
    LostPhase: 'LostPhase',
    NotCreated: 'NotCreated'
} as const;

export type ClusterObjectIssueType = typeof ClusterObjectIssueType[keyof typeof ClusterObjectIssueType];


/**
 * an object that exists in the cluster with a list of issues found while syncing it to the cluster.
 * @export
 * @interface ClusterObjectStatus
 */
export interface ClusterObjectStatus {
    /**
     * the scope type of the resource.
     * @type {string}
     * @memberof ClusterObjectStatus
     */
    'scopeType': string;
    /**
     * The id of the cluster, department or project, depending on the scope type.
     * @type {string}
     * @memberof ClusterObjectStatus
     */
    'scopeId': string;
    /**
     * 
     * @type {ClusterResourceType}
     * @memberof ClusterObjectStatus
     */
    'resourceType': ClusterResourceType;
    /**
     * 
     * @type {string}
     * @memberof ClusterObjectStatus
     */
    'resourceName': string;
    /**
     * 
     * @type {SecretType}
     * @memberof ClusterObjectStatus
     */
    'secretType'?: SecretType;
    /**
     * 
     * @type {Array<ClusterObjectIssue>}
     * @memberof ClusterObjectStatus
     */
    'issues'?: Array<ClusterObjectIssue>;
}
/**
 * 
 * @export
 * @interface ClusterObjectsStatus
 */
export interface ClusterObjectsStatus {
    /**
     * 
     * @type {Array<ClusterObjectStatus>}
     * @memberof ClusterObjectsStatus
     */
    'entries': Array<ClusterObjectStatus>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ClusterResourceType = {
    Pvc: 'pvc',
    Secret: 'secret',
    ConfigMap: 'config-map'
} as const;

export type ClusterResourceType = typeof ClusterResourceType[keyof typeof ClusterResourceType];


/**
 * 
 * @export
 * @interface ComplianceIssues
 */
export interface ComplianceIssues {
    /**
     * 
     * @type {Array<ComplianceIssuesComplianceIssuesInner>}
     * @memberof ComplianceIssues
     */
    'complianceIssues'?: Array<ComplianceIssuesComplianceIssuesInner>;
}
/**
 * 
 * @export
 * @interface ComplianceIssuesComplianceIssuesInner
 */
export interface ComplianceIssuesComplianceIssuesInner {
    /**
     * 
     * @type {string}
     * @memberof ComplianceIssuesComplianceIssuesInner
     */
    'field': string;
    /**
     * 
     * @type {string}
     * @memberof ComplianceIssuesComplianceIssuesInner
     */
    'details': string;
}
/**
 * 
 * @export
 * @interface ConfigMap
 */
export interface ConfigMap {
    /**
     * name of the ConfigMap.
     * @type {string}
     * @memberof ConfigMap
     */
    'name': string;
    /**
     * list of issues that was found while syncing the resource to the cluster
     * @type {Array<Issue>}
     * @memberof ConfigMap
     */
    'issues'?: Array<Issue>;
}
/**
 * 
 * @export
 * @interface ConfigMapListInfo
 */
export interface ConfigMapListInfo {
    /**
     * The id of the cluster.
     * @type {string}
     * @memberof ConfigMapListInfo
     */
    'clusterId': string;
    /**
     * the scope type of the resource.
     * @type {string}
     * @memberof ConfigMapListInfo
     */
    'scopeType': string;
    /**
     * The id of the cluster, department or project, depending on the scope type.
     * @type {string}
     * @memberof ConfigMapListInfo
     */
    'scopeId': string;
    /**
     * name of the ConfigMap.
     * @type {string}
     * @memberof ConfigMapListInfo
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ConfigMaps
 */
export interface ConfigMaps {
    /**
     * 
     * @type {Array<ConfigMap>}
     * @memberof ConfigMaps
     */
    'configmaps': Array<ConfigMap>;
}
/**
 * 
 * @export
 * @interface ConfigMapsListResponse
 */
export interface ConfigMapsListResponse {
    /**
     * 
     * @type {Array<ConfigMapListInfo>}
     * @memberof ConfigMapsListResponse
     */
    'configMaps': Array<ConfigMapListInfo>;
}
/**
 * 
 * @export
 * @interface CreateClusterWidePvcV1Request
 */
export interface CreateClusterWidePvcV1Request {
    /**
     * 
     * @type {string}
     * @memberof CreateClusterWidePvcV1Request
     */
    'unused'?: string | null;
}
/**
 * an issue that was found while syncing the resource
 * @export
 * @interface Issue
 */
export interface Issue {
    /**
     * the namespace of the resource
     * @type {string}
     * @memberof Issue
     */
    'namespace': string;
    /**
     * the issue found
     * @type {string}
     * @memberof Issue
     */
    'issue': string;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {number}
     * @memberof ModelError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'details'?: string;
}
/**
 * the origin of the resource. if not specified, customer existing resource is assumed. resource with origin \'assets\' is originated from an asset in the control plane
 * @export
 * @enum {string}
 */

export const Origin = {
    Assets: 'assets',
    Existing: 'existing'
} as const;

export type Origin = typeof Origin[keyof typeof Origin];


/**
 * 
 * @export
 * @interface PVC
 */
export interface PVC {
    /**
     * name of the pvc.
     * @type {string}
     * @memberof PVC
     */
    'name': string;
    /**
     * 
     * @type {Origin}
     * @memberof PVC
     */
    'origin'?: Origin | null;
    /**
     * list of issues that was found while syncing the resource to the cluster
     * @type {Array<Issue>}
     * @memberof PVC
     */
    'issues'?: Array<Issue>;
}
/**
 * 
 * @export
 * @interface PVCCreationRequest
 */
export interface PVCCreationRequest {
    /**
     * 
     * @type {Origin}
     * @memberof PVCCreationRequest
     */
    'origin'?: Origin | null;
    /**
     * list of issues that was found while syncing the resource to the cluster
     * @type {Array<Issue>}
     * @memberof PVCCreationRequest
     */
    'issues'?: Array<Issue>;
}
/**
 * 
 * @export
 * @interface PVCListInfo
 */
export interface PVCListInfo {
    /**
     * The id of the cluster.
     * @type {string}
     * @memberof PVCListInfo
     */
    'clusterId': string;
    /**
     * the scope type of the resource.
     * @type {string}
     * @memberof PVCListInfo
     */
    'scopeType': string;
    /**
     * The id of the cluster, department or project, depending on the scope type.
     * @type {string}
     * @memberof PVCListInfo
     */
    'scopeId': string;
    /**
     * name of the pvc.
     * @type {string}
     * @memberof PVCListInfo
     */
    'name': string;
    /**
     * 
     * @type {Origin}
     * @memberof PVCListInfo
     */
    'origin': Origin | null;
}
/**
 * 
 * @export
 * @interface PVCListResponse
 */
export interface PVCListResponse {
    /**
     * 
     * @type {Array<PVCListInfo>}
     * @memberof PVCListResponse
     */
    'pvcs': Array<PVCListInfo>;
}
/**
 * 
 * @export
 * @interface PVCNames
 */
export interface PVCNames {
    /**
     * 
     * @type {Array<string>}
     * @memberof PVCNames
     */
    'pvcs': Array<string>;
}
/**
 * 
 * @export
 * @interface PVCs
 */
export interface PVCs {
    /**
     * 
     * @type {Array<PVC>}
     * @memberof PVCs
     */
    'pvcs': Array<PVC>;
}
/**
 * 
 * @export
 * @interface Secret
 */
export interface Secret {
    /**
     * name of the secret.
     * @type {string}
     * @memberof Secret
     */
    'name': string;
    /**
     * 
     * @type {SecretType}
     * @memberof Secret
     */
    'type': SecretType;
    /**
     * 
     * @type {Origin}
     * @memberof Secret
     */
    'origin'?: Origin | null;
    /**
     * list of issues that was found while syncing the resource to the cluster
     * @type {Array<Issue>}
     * @memberof Secret
     */
    'issues'?: Array<Issue>;
}
/**
 * 
 * @export
 * @interface SecretCreationRequest
 */
export interface SecretCreationRequest {
    /**
     * 
     * @type {SecretType}
     * @memberof SecretCreationRequest
     */
    'type': SecretType;
    /**
     * 
     * @type {Origin}
     * @memberof SecretCreationRequest
     */
    'origin'?: Origin | null;
    /**
     * list of issues that was found while syncing the resource to the cluster
     * @type {Array<Issue>}
     * @memberof SecretCreationRequest
     */
    'issues'?: Array<Issue>;
}
/**
 * 
 * @export
 * @interface SecretListInfo
 */
export interface SecretListInfo {
    /**
     * The id of the cluster.
     * @type {string}
     * @memberof SecretListInfo
     */
    'clusterId': string;
    /**
     * the scope type of the resource.
     * @type {string}
     * @memberof SecretListInfo
     */
    'scopeType': string;
    /**
     * The id of the cluster, department or project, depending on the scope type.
     * @type {string}
     * @memberof SecretListInfo
     */
    'scopeId': string;
    /**
     * name of the secret.
     * @type {string}
     * @memberof SecretListInfo
     */
    'name': string;
    /**
     * 
     * @type {SecretType}
     * @memberof SecretListInfo
     */
    'type': SecretType;
    /**
     * 
     * @type {Origin}
     * @memberof SecretListInfo
     */
    'origin': Origin | null;
}
/**
 * 
 * @export
 * @interface SecretListResponse
 */
export interface SecretListResponse {
    /**
     * 
     * @type {Array<SecretListInfo>}
     * @memberof SecretListResponse
     */
    'secrets': Array<SecretListInfo>;
}
/**
 * type of the secret.
 * @export
 * @enum {string}
 */

export const SecretType = {
    AccessKey: 'access-key',
    Password: 'password',
    DockerRegistry: 'docker-registry',
    Generic: 'generic'
} as const;

export type SecretType = typeof SecretType[keyof typeof SecretType];


/**
 * 
 * @export
 * @interface Secrets
 */
export interface Secrets {
    /**
     * 
     * @type {Array<Secret>}
     * @memberof Secrets
     */
    'secrets': Array<Secret>;
}
/**
 * 
 * @export
 * @interface SinglePVC
 */
export interface SinglePVC {
    /**
     * name of the pvc.
     * @type {string}
     * @memberof SinglePVC
     */
    'pvc_name': string;
}
/**
 * 
 * @export
 * @interface SingleSecret
 */
export interface SingleSecret {
    /**
     * name of the secret.
     * @type {string}
     * @memberof SingleSecret
     */
    'secret_name': string;
}
/**
 * 
 * @export
 * @interface StorageClass
 */
export interface StorageClass {
    /**
     * Cluster ID which has sent the resource info
     * @type {string}
     * @memberof StorageClass
     */
    'cluster_id'?: string;
    /**
     * Storage class name
     * @type {string}
     * @memberof StorageClass
     */
    'storage_class_name': string;
    /**
     * 
     * @type {StorageClassSpec}
     * @memberof StorageClass
     */
    'Spec'?: StorageClassSpec;
}
/**
 * 
 * @export
 * @interface StorageClassSpec
 */
export interface StorageClassSpec {
    /**
     * The provisioner type (unique name)
     * @type {string}
     * @memberof StorageClassSpec
     */
    'provisioner'?: string;
    /**
     * Indicates whether the storage class allows volume expansion
     * @type {boolean}
     * @memberof StorageClassSpec
     */
    'allow_volume_expansion'?: boolean;
    /**
     * Indicates whether the storage class is the default one
     * @type {boolean}
     * @memberof StorageClassSpec
     */
    'is_default': boolean;
}
/**
 * 
 * @export
 * @interface StorageClasses
 */
export interface StorageClasses {
    /**
     * 
     * @type {Array<StorageClass>}
     * @memberof StorageClasses
     */
    'items': Array<StorageClass>;
}
/**
 * 
 * @export
 * @interface SubmissionError
 */
export interface SubmissionError {
    /**
     * 
     * @type {number}
     * @memberof SubmissionError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof SubmissionError
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionError
     */
    'details'?: string;
    /**
     * 
     * @type {Array<ComplianceIssuesComplianceIssuesInner>}
     * @memberof SubmissionError
     */
    'complianceIssues'?: Array<ComplianceIssuesComplianceIssuesInner>;
}
/**
 * 
 * @export
 * @interface V1Error
 */
export interface V1Error {
    /**
     * 
     * @type {number}
     * @memberof V1Error
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof V1Error
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface V1StorageClass
 */
export interface V1StorageClass {
    /**
     * Identifier of the storage class
     * @type {string}
     * @memberof V1StorageClass
     */
    'storage_class_name': string;
    /**
     * Indicator if the storage class is the default storage class
     * @type {boolean}
     * @memberof V1StorageClass
     */
    'is_default'?: boolean;
}

/**
 * ClusterObjectsStatusApi - axios parameter creator
 * @export
 */
export const ClusterObjectsStatusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary list of objects in the cluster and their found issues
         * @param {ClusterResourceType} [resourceType] filter by resource type
         * @param {string} [secretType] filter by secret type. supported values - password, access-key, docker-registry.
         * @param {string} [scopeId] filter by scope id
         * @param {string} [scopeType] The type of resource we want to filter by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClusterObjectsStatus: async (resourceType?: ClusterResourceType, secretType?: string, scopeId?: string, scopeType?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/internal/api/v1/cluster-objects/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (resourceType !== undefined) {
                localVarQueryParameter['resourceType'] = resourceType;
            }

            if (secretType !== undefined) {
                localVarQueryParameter['secretType'] = secretType;
            }

            if (scopeId !== undefined) {
                localVarQueryParameter['scopeId'] = scopeId;
            }

            if (scopeType !== undefined) {
                localVarQueryParameter['scopeType'] = scopeType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClusterObjectsStatusApi - functional programming interface
 * @export
 */
export const ClusterObjectsStatusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClusterObjectsStatusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary list of objects in the cluster and their found issues
         * @param {ClusterResourceType} [resourceType] filter by resource type
         * @param {string} [secretType] filter by secret type. supported values - password, access-key, docker-registry.
         * @param {string} [scopeId] filter by scope id
         * @param {string} [scopeType] The type of resource we want to filter by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listClusterObjectsStatus(resourceType?: ClusterResourceType, secretType?: string, scopeId?: string, scopeType?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClusterObjectsStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listClusterObjectsStatus(resourceType, secretType, scopeId, scopeType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClusterObjectsStatusApi - factory interface
 * @export
 */
export const ClusterObjectsStatusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClusterObjectsStatusApiFp(configuration)
    return {
        /**
         * 
         * @summary list of objects in the cluster and their found issues
         * @param {ClusterResourceType} [resourceType] filter by resource type
         * @param {string} [secretType] filter by secret type. supported values - password, access-key, docker-registry.
         * @param {string} [scopeId] filter by scope id
         * @param {string} [scopeType] The type of resource we want to filter by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClusterObjectsStatus(resourceType?: ClusterResourceType, secretType?: string, scopeId?: string, scopeType?: string, options?: any): AxiosPromise<ClusterObjectsStatus> {
            return localVarFp.listClusterObjectsStatus(resourceType, secretType, scopeId, scopeType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClusterObjectsStatusApi - object-oriented interface
 * @export
 * @class ClusterObjectsStatusApi
 * @extends {BaseAPI}
 */
export class ClusterObjectsStatusApi extends BaseAPI {
    /**
     * 
     * @summary list of objects in the cluster and their found issues
     * @param {ClusterResourceType} [resourceType] filter by resource type
     * @param {string} [secretType] filter by secret type. supported values - password, access-key, docker-registry.
     * @param {string} [scopeId] filter by scope id
     * @param {string} [scopeType] The type of resource we want to filter by.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterObjectsStatusApi
     */
    public listClusterObjectsStatus(resourceType?: ClusterResourceType, secretType?: string, scopeId?: string, scopeType?: string, options?: AxiosRequestConfig) {
        return ClusterObjectsStatusApiFp(this.configuration).listClusterObjectsStatus(resourceType, secretType, scopeId, scopeType, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConfigMapsApi - axios parameter creator
 * @export
 */
export const ConfigMapsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary upload the details of cluster-wide ConfigMap to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} configMapName The Config Map name.
         * @param {ConfigMap} configMap 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClusterWideConfigmapV1: async (clusterUuid: string, configMapName: string, configMap: ConfigMap, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('createClusterWideConfigmapV1', 'clusterUuid', clusterUuid)
            // verify required parameter 'configMapName' is not null or undefined
            assertParamExists('createClusterWideConfigmapV1', 'configMapName', configMapName)
            // verify required parameter 'configMap' is not null or undefined
            assertParamExists('createClusterWideConfigmapV1', 'configMap', configMap)
            const localVarPath = `/api/v1/config-maps/{clusterUuid}/{configMapName}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"configMapName"}}`, encodeURIComponent(String(configMapName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(configMap, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary upload the details of department-wide ConfigMap to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {string} configMapName The Config Map name.
         * @param {ConfigMap} configMap 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDepartmentsWideConfigmapV1: async (clusterUuid: string, departmentId: string, configMapName: string, configMap: ConfigMap, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('createDepartmentsWideConfigmapV1', 'clusterUuid', clusterUuid)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('createDepartmentsWideConfigmapV1', 'departmentId', departmentId)
            // verify required parameter 'configMapName' is not null or undefined
            assertParamExists('createDepartmentsWideConfigmapV1', 'configMapName', configMapName)
            // verify required parameter 'configMap' is not null or undefined
            assertParamExists('createDepartmentsWideConfigmapV1', 'configMap', configMap)
            const localVarPath = `/api/v1/config-maps/{clusterUuid}/departments/{departmentId}/{configMapName}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)))
                .replace(`{${"configMapName"}}`, encodeURIComponent(String(configMapName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(configMap, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary upload the details of project ConfigMap to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {string} configMapName The Config Map name.
         * @param {ConfigMap} configMap 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProjectsConfigmapV1: async (clusterUuid: string, namespace: string, configMapName: string, configMap: ConfigMap, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('createProjectsConfigmapV1', 'clusterUuid', clusterUuid)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('createProjectsConfigmapV1', 'namespace', namespace)
            // verify required parameter 'configMapName' is not null or undefined
            assertParamExists('createProjectsConfigmapV1', 'configMapName', configMapName)
            // verify required parameter 'configMap' is not null or undefined
            assertParamExists('createProjectsConfigmapV1', 'configMap', configMap)
            const localVarPath = `/api/v1/config-maps/{clusterUuid}/namespaces/{namespace}/{configMapName}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)))
                .replace(`{${"configMapName"}}`, encodeURIComponent(String(configMapName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(configMap, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete cluster-wide ConfigMap
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} configMapName The Config Map name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClusterWideConfigmapV1: async (clusterUuid: string, configMapName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('deleteClusterWideConfigmapV1', 'clusterUuid', clusterUuid)
            // verify required parameter 'configMapName' is not null or undefined
            assertParamExists('deleteClusterWideConfigmapV1', 'configMapName', configMapName)
            const localVarPath = `/api/v1/config-maps/{clusterUuid}/{configMapName}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"configMapName"}}`, encodeURIComponent(String(configMapName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete department-wide ConfigMap
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {string} configMapName The Config Map name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDepartmentsWideConfigmapV1: async (clusterUuid: string, departmentId: string, configMapName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('deleteDepartmentsWideConfigmapV1', 'clusterUuid', clusterUuid)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('deleteDepartmentsWideConfigmapV1', 'departmentId', departmentId)
            // verify required parameter 'configMapName' is not null or undefined
            assertParamExists('deleteDepartmentsWideConfigmapV1', 'configMapName', configMapName)
            const localVarPath = `/api/v1/config-maps/{clusterUuid}/departments/{departmentId}/{configMapName}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)))
                .replace(`{${"configMapName"}}`, encodeURIComponent(String(configMapName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete project ConfigMap
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {string} configMapName The Config Map name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectsConfigmapV1: async (clusterUuid: string, namespace: string, configMapName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('deleteProjectsConfigmapV1', 'clusterUuid', clusterUuid)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('deleteProjectsConfigmapV1', 'namespace', namespace)
            // verify required parameter 'configMapName' is not null or undefined
            assertParamExists('deleteProjectsConfigmapV1', 'configMapName', configMapName)
            const localVarPath = `/api/v1/config-maps/{clusterUuid}/namespaces/{namespace}/{configMapName}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)))
                .replace(`{${"configMapName"}}`, encodeURIComponent(String(configMapName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list details of ConfigMaps
         * @param {string} [scopeType] The type of resource we want to filter by.
         * @param {string} [scopeId] filter by scope id
         * @param {string} [name] filter by name
         * @param {string} [clusterId] Filter using the Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConfigMaps: async (scopeType?: string, scopeId?: string, name?: string, clusterId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/config-maps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (scopeType !== undefined) {
                localVarQueryParameter['scopeType'] = scopeType;
            }

            if (scopeId !== undefined) {
                localVarQueryParameter['scopeId'] = scopeId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sync the details of cluster-wide ConfigMaps to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {ConfigMaps} configMaps 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncClusterWideConfigmapsV1: async (clusterUuid: string, configMaps: ConfigMaps, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('syncClusterWideConfigmapsV1', 'clusterUuid', clusterUuid)
            // verify required parameter 'configMaps' is not null or undefined
            assertParamExists('syncClusterWideConfigmapsV1', 'configMaps', configMaps)
            const localVarPath = `/api/v1/config-maps/{clusterUuid}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(configMaps, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sync the details of department-wide ConfigMaps to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {ConfigMaps} configMaps 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncDepartmentsWideConfigmapV1: async (clusterUuid: string, departmentId: string, configMaps: ConfigMaps, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('syncDepartmentsWideConfigmapV1', 'clusterUuid', clusterUuid)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('syncDepartmentsWideConfigmapV1', 'departmentId', departmentId)
            // verify required parameter 'configMaps' is not null or undefined
            assertParamExists('syncDepartmentsWideConfigmapV1', 'configMaps', configMaps)
            const localVarPath = `/api/v1/config-maps/{clusterUuid}/departments/{departmentId}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(configMaps, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sync the details of project ConfigMaps to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {ConfigMaps} configMaps 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncProjectsConfigmapsV1: async (clusterUuid: string, namespace: string, configMaps: ConfigMaps, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('syncProjectsConfigmapsV1', 'clusterUuid', clusterUuid)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('syncProjectsConfigmapsV1', 'namespace', namespace)
            // verify required parameter 'configMaps' is not null or undefined
            assertParamExists('syncProjectsConfigmapsV1', 'configMaps', configMaps)
            const localVarPath = `/api/v1/config-maps/{clusterUuid}/namespaces/{namespace}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(configMaps, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigMapsApi - functional programming interface
 * @export
 */
export const ConfigMapsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConfigMapsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary upload the details of cluster-wide ConfigMap to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} configMapName The Config Map name.
         * @param {ConfigMap} configMap 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createClusterWideConfigmapV1(clusterUuid: string, configMapName: string, configMap: ConfigMap, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigMap>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createClusterWideConfigmapV1(clusterUuid, configMapName, configMap, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary upload the details of department-wide ConfigMap to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {string} configMapName The Config Map name.
         * @param {ConfigMap} configMap 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDepartmentsWideConfigmapV1(clusterUuid: string, departmentId: string, configMapName: string, configMap: ConfigMap, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigMap>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDepartmentsWideConfigmapV1(clusterUuid, departmentId, configMapName, configMap, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary upload the details of project ConfigMap to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {string} configMapName The Config Map name.
         * @param {ConfigMap} configMap 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProjectsConfigmapV1(clusterUuid: string, namespace: string, configMapName: string, configMap: ConfigMap, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigMap>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProjectsConfigmapV1(clusterUuid, namespace, configMapName, configMap, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete cluster-wide ConfigMap
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} configMapName The Config Map name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteClusterWideConfigmapV1(clusterUuid: string, configMapName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteClusterWideConfigmapV1(clusterUuid, configMapName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete department-wide ConfigMap
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {string} configMapName The Config Map name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDepartmentsWideConfigmapV1(clusterUuid: string, departmentId: string, configMapName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDepartmentsWideConfigmapV1(clusterUuid, departmentId, configMapName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete project ConfigMap
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {string} configMapName The Config Map name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProjectsConfigmapV1(clusterUuid: string, namespace: string, configMapName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProjectsConfigmapV1(clusterUuid, namespace, configMapName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list details of ConfigMaps
         * @param {string} [scopeType] The type of resource we want to filter by.
         * @param {string} [scopeId] filter by scope id
         * @param {string} [name] filter by name
         * @param {string} [clusterId] Filter using the Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listConfigMaps(scopeType?: string, scopeId?: string, name?: string, clusterId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigMapsListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listConfigMaps(scopeType, scopeId, name, clusterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary sync the details of cluster-wide ConfigMaps to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {ConfigMaps} configMaps 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncClusterWideConfigmapsV1(clusterUuid: string, configMaps: ConfigMaps, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigMaps>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncClusterWideConfigmapsV1(clusterUuid, configMaps, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary sync the details of department-wide ConfigMaps to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {ConfigMaps} configMaps 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncDepartmentsWideConfigmapV1(clusterUuid: string, departmentId: string, configMaps: ConfigMaps, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigMaps>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncDepartmentsWideConfigmapV1(clusterUuid, departmentId, configMaps, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary sync the details of project ConfigMaps to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {ConfigMaps} configMaps 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncProjectsConfigmapsV1(clusterUuid: string, namespace: string, configMaps: ConfigMaps, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigMaps>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncProjectsConfigmapsV1(clusterUuid, namespace, configMaps, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConfigMapsApi - factory interface
 * @export
 */
export const ConfigMapsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConfigMapsApiFp(configuration)
    return {
        /**
         * 
         * @summary upload the details of cluster-wide ConfigMap to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} configMapName The Config Map name.
         * @param {ConfigMap} configMap 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClusterWideConfigmapV1(clusterUuid: string, configMapName: string, configMap: ConfigMap, options?: any): AxiosPromise<ConfigMap> {
            return localVarFp.createClusterWideConfigmapV1(clusterUuid, configMapName, configMap, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary upload the details of department-wide ConfigMap to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {string} configMapName The Config Map name.
         * @param {ConfigMap} configMap 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDepartmentsWideConfigmapV1(clusterUuid: string, departmentId: string, configMapName: string, configMap: ConfigMap, options?: any): AxiosPromise<ConfigMap> {
            return localVarFp.createDepartmentsWideConfigmapV1(clusterUuid, departmentId, configMapName, configMap, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary upload the details of project ConfigMap to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {string} configMapName The Config Map name.
         * @param {ConfigMap} configMap 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProjectsConfigmapV1(clusterUuid: string, namespace: string, configMapName: string, configMap: ConfigMap, options?: any): AxiosPromise<ConfigMap> {
            return localVarFp.createProjectsConfigmapV1(clusterUuid, namespace, configMapName, configMap, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete cluster-wide ConfigMap
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} configMapName The Config Map name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClusterWideConfigmapV1(clusterUuid: string, configMapName: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteClusterWideConfigmapV1(clusterUuid, configMapName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete department-wide ConfigMap
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {string} configMapName The Config Map name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDepartmentsWideConfigmapV1(clusterUuid: string, departmentId: string, configMapName: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDepartmentsWideConfigmapV1(clusterUuid, departmentId, configMapName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete project ConfigMap
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {string} configMapName The Config Map name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectsConfigmapV1(clusterUuid: string, namespace: string, configMapName: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProjectsConfigmapV1(clusterUuid, namespace, configMapName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list details of ConfigMaps
         * @param {string} [scopeType] The type of resource we want to filter by.
         * @param {string} [scopeId] filter by scope id
         * @param {string} [name] filter by name
         * @param {string} [clusterId] Filter using the Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConfigMaps(scopeType?: string, scopeId?: string, name?: string, clusterId?: string, options?: any): AxiosPromise<ConfigMapsListResponse> {
            return localVarFp.listConfigMaps(scopeType, scopeId, name, clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sync the details of cluster-wide ConfigMaps to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {ConfigMaps} configMaps 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncClusterWideConfigmapsV1(clusterUuid: string, configMaps: ConfigMaps, options?: any): AxiosPromise<ConfigMaps> {
            return localVarFp.syncClusterWideConfigmapsV1(clusterUuid, configMaps, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sync the details of department-wide ConfigMaps to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {ConfigMaps} configMaps 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncDepartmentsWideConfigmapV1(clusterUuid: string, departmentId: string, configMaps: ConfigMaps, options?: any): AxiosPromise<ConfigMaps> {
            return localVarFp.syncDepartmentsWideConfigmapV1(clusterUuid, departmentId, configMaps, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sync the details of project ConfigMaps to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {ConfigMaps} configMaps 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncProjectsConfigmapsV1(clusterUuid: string, namespace: string, configMaps: ConfigMaps, options?: any): AxiosPromise<ConfigMaps> {
            return localVarFp.syncProjectsConfigmapsV1(clusterUuid, namespace, configMaps, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConfigMapsApi - object-oriented interface
 * @export
 * @class ConfigMapsApi
 * @extends {BaseAPI}
 */
export class ConfigMapsApi extends BaseAPI {
    /**
     * 
     * @summary upload the details of cluster-wide ConfigMap to the control plane
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} configMapName The Config Map name.
     * @param {ConfigMap} configMap 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigMapsApi
     */
    public createClusterWideConfigmapV1(clusterUuid: string, configMapName: string, configMap: ConfigMap, options?: AxiosRequestConfig) {
        return ConfigMapsApiFp(this.configuration).createClusterWideConfigmapV1(clusterUuid, configMapName, configMap, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary upload the details of department-wide ConfigMap to the control plane
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} departmentId The id of the department.
     * @param {string} configMapName The Config Map name.
     * @param {ConfigMap} configMap 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigMapsApi
     */
    public createDepartmentsWideConfigmapV1(clusterUuid: string, departmentId: string, configMapName: string, configMap: ConfigMap, options?: AxiosRequestConfig) {
        return ConfigMapsApiFp(this.configuration).createDepartmentsWideConfigmapV1(clusterUuid, departmentId, configMapName, configMap, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary upload the details of project ConfigMap to the control plane
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} namespace The namespace of the project.
     * @param {string} configMapName The Config Map name.
     * @param {ConfigMap} configMap 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigMapsApi
     */
    public createProjectsConfigmapV1(clusterUuid: string, namespace: string, configMapName: string, configMap: ConfigMap, options?: AxiosRequestConfig) {
        return ConfigMapsApiFp(this.configuration).createProjectsConfigmapV1(clusterUuid, namespace, configMapName, configMap, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete cluster-wide ConfigMap
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} configMapName The Config Map name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigMapsApi
     */
    public deleteClusterWideConfigmapV1(clusterUuid: string, configMapName: string, options?: AxiosRequestConfig) {
        return ConfigMapsApiFp(this.configuration).deleteClusterWideConfigmapV1(clusterUuid, configMapName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete department-wide ConfigMap
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} departmentId The id of the department.
     * @param {string} configMapName The Config Map name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigMapsApi
     */
    public deleteDepartmentsWideConfigmapV1(clusterUuid: string, departmentId: string, configMapName: string, options?: AxiosRequestConfig) {
        return ConfigMapsApiFp(this.configuration).deleteDepartmentsWideConfigmapV1(clusterUuid, departmentId, configMapName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete project ConfigMap
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} namespace The namespace of the project.
     * @param {string} configMapName The Config Map name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigMapsApi
     */
    public deleteProjectsConfigmapV1(clusterUuid: string, namespace: string, configMapName: string, options?: AxiosRequestConfig) {
        return ConfigMapsApiFp(this.configuration).deleteProjectsConfigmapV1(clusterUuid, namespace, configMapName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list details of ConfigMaps
     * @param {string} [scopeType] The type of resource we want to filter by.
     * @param {string} [scopeId] filter by scope id
     * @param {string} [name] filter by name
     * @param {string} [clusterId] Filter using the Universally Unique Identifier (UUID) of the cluster.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigMapsApi
     */
    public listConfigMaps(scopeType?: string, scopeId?: string, name?: string, clusterId?: string, options?: AxiosRequestConfig) {
        return ConfigMapsApiFp(this.configuration).listConfigMaps(scopeType, scopeId, name, clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sync the details of cluster-wide ConfigMaps to the control plane
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {ConfigMaps} configMaps 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigMapsApi
     */
    public syncClusterWideConfigmapsV1(clusterUuid: string, configMaps: ConfigMaps, options?: AxiosRequestConfig) {
        return ConfigMapsApiFp(this.configuration).syncClusterWideConfigmapsV1(clusterUuid, configMaps, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sync the details of department-wide ConfigMaps to the control plane
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} departmentId The id of the department.
     * @param {ConfigMaps} configMaps 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigMapsApi
     */
    public syncDepartmentsWideConfigmapV1(clusterUuid: string, departmentId: string, configMaps: ConfigMaps, options?: AxiosRequestConfig) {
        return ConfigMapsApiFp(this.configuration).syncDepartmentsWideConfigmapV1(clusterUuid, departmentId, configMaps, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sync the details of project ConfigMaps to the control plane
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} namespace The namespace of the project.
     * @param {ConfigMaps} configMaps 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigMapsApi
     */
    public syncProjectsConfigmapsV1(clusterUuid: string, namespace: string, configMaps: ConfigMaps, options?: AxiosRequestConfig) {
        return ConfigMapsApiFp(this.configuration).syncProjectsConfigmapsV1(clusterUuid, namespace, configMaps, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async health(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.health(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthApiFp(configuration)
    return {
        /**
         * 
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health(options?: any): AxiosPromise<void> {
            return localVarFp.health(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI {
    /**
     * 
     * @summary health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    public health(options?: AxiosRequestConfig) {
        return HealthApiFp(this.configuration).health(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PVCsApi - axios parameter creator
 * @export
 */
export const PVCsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * this API is used by cluster versions < 2.17
         * @summary upload the name of cluster-wide pvc to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} pvcName The pvc name.
         * @param {CreateClusterWidePvcV1Request} [createClusterWidePvcV1Request] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createClusterWidePvcV1: async (clusterUuid: string, pvcName: string, createClusterWidePvcV1Request?: CreateClusterWidePvcV1Request, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('createClusterWidePvcV1', 'clusterUuid', clusterUuid)
            // verify required parameter 'pvcName' is not null or undefined
            assertParamExists('createClusterWidePvcV1', 'pvcName', pvcName)
            const localVarPath = `/api/v1/pvcs/{clusterUuid}/{pvcName}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"pvcName"}}`, encodeURIComponent(String(pvcName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createClusterWidePvcV1Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary upload the details of cluster-wide pvc to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} pvcName The pvc name.
         * @param {PVCCreationRequest} [pVCCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClusterWidePvcV2: async (clusterUuid: string, pvcName: string, pVCCreationRequest?: PVCCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('createClusterWidePvcV2', 'clusterUuid', clusterUuid)
            // verify required parameter 'pvcName' is not null or undefined
            assertParamExists('createClusterWidePvcV2', 'pvcName', pvcName)
            const localVarPath = `/api/v2/pvcs/{clusterUuid}/{pvcName}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"pvcName"}}`, encodeURIComponent(String(pvcName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pVCCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * this API is used by cluster versions < 2.17
         * @summary upload the name of department-wide pvc to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {string} pvcName The pvc name.
         * @param {CreateClusterWidePvcV1Request} [createClusterWidePvcV1Request] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createDepartmentWidePvcV1: async (clusterUuid: string, departmentId: string, pvcName: string, createClusterWidePvcV1Request?: CreateClusterWidePvcV1Request, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('createDepartmentWidePvcV1', 'clusterUuid', clusterUuid)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('createDepartmentWidePvcV1', 'departmentId', departmentId)
            // verify required parameter 'pvcName' is not null or undefined
            assertParamExists('createDepartmentWidePvcV1', 'pvcName', pvcName)
            const localVarPath = `/api/v1/pvcs/{clusterUuid}/department/{departmentId}/{pvcName}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)))
                .replace(`{${"pvcName"}}`, encodeURIComponent(String(pvcName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createClusterWidePvcV1Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary upload the details of department-wide pvc to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {string} pvcName The pvc name.
         * @param {PVCCreationRequest} pVCCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDepartmentWidePvcV2: async (clusterUuid: string, departmentId: string, pvcName: string, pVCCreationRequest: PVCCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('createDepartmentWidePvcV2', 'clusterUuid', clusterUuid)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('createDepartmentWidePvcV2', 'departmentId', departmentId)
            // verify required parameter 'pvcName' is not null or undefined
            assertParamExists('createDepartmentWidePvcV2', 'pvcName', pvcName)
            // verify required parameter 'pVCCreationRequest' is not null or undefined
            assertParamExists('createDepartmentWidePvcV2', 'pVCCreationRequest', pVCCreationRequest)
            const localVarPath = `/api/v2/pvcs/{clusterUuid}/department/{departmentId}/{pvcName}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)))
                .replace(`{${"pvcName"}}`, encodeURIComponent(String(pvcName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pVCCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary upload the details of department-wide pvc to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {string} pvcName The pvc name.
         * @param {PVCCreationRequest} pVCCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDepartmentsWidePvcV2: async (clusterUuid: string, departmentId: string, pvcName: string, pVCCreationRequest: PVCCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('createDepartmentsWidePvcV2', 'clusterUuid', clusterUuid)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('createDepartmentsWidePvcV2', 'departmentId', departmentId)
            // verify required parameter 'pvcName' is not null or undefined
            assertParamExists('createDepartmentsWidePvcV2', 'pvcName', pvcName)
            // verify required parameter 'pVCCreationRequest' is not null or undefined
            assertParamExists('createDepartmentsWidePvcV2', 'pVCCreationRequest', pVCCreationRequest)
            const localVarPath = `/api/v2/pvcs/{clusterUuid}/departments/{departmentId}/{pvcName}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)))
                .replace(`{${"pvcName"}}`, encodeURIComponent(String(pvcName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pVCCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * this API is used by cluster versions < 2.17
         * @summary upload the name of project pvc to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {string} pvcName The pvc name.
         * @param {CreateClusterWidePvcV1Request} [createClusterWidePvcV1Request] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createProjectPvcV1: async (clusterUuid: string, namespace: string, pvcName: string, createClusterWidePvcV1Request?: CreateClusterWidePvcV1Request, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('createProjectPvcV1', 'clusterUuid', clusterUuid)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('createProjectPvcV1', 'namespace', namespace)
            // verify required parameter 'pvcName' is not null or undefined
            assertParamExists('createProjectPvcV1', 'pvcName', pvcName)
            const localVarPath = `/api/v1/pvcs/{clusterUuid}/namespace/{namespace}/{pvcName}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)))
                .replace(`{${"pvcName"}}`, encodeURIComponent(String(pvcName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createClusterWidePvcV1Request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary upload the details of project pvc to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {string} pvcName The pvc name.
         * @param {PVCCreationRequest} pVCCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProjectPvcV2: async (clusterUuid: string, namespace: string, pvcName: string, pVCCreationRequest: PVCCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('createProjectPvcV2', 'clusterUuid', clusterUuid)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('createProjectPvcV2', 'namespace', namespace)
            // verify required parameter 'pvcName' is not null or undefined
            assertParamExists('createProjectPvcV2', 'pvcName', pvcName)
            // verify required parameter 'pVCCreationRequest' is not null or undefined
            assertParamExists('createProjectPvcV2', 'pVCCreationRequest', pVCCreationRequest)
            const localVarPath = `/api/v2/pvcs/{clusterUuid}/namespace/{namespace}/{pvcName}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)))
                .replace(`{${"pvcName"}}`, encodeURIComponent(String(pvcName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pVCCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary upload the details of project pvc to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {string} pvcName The pvc name.
         * @param {PVCCreationRequest} pVCCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProjectsPvcV2: async (clusterUuid: string, namespace: string, pvcName: string, pVCCreationRequest: PVCCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('createProjectsPvcV2', 'clusterUuid', clusterUuid)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('createProjectsPvcV2', 'namespace', namespace)
            // verify required parameter 'pvcName' is not null or undefined
            assertParamExists('createProjectsPvcV2', 'pvcName', pvcName)
            // verify required parameter 'pVCCreationRequest' is not null or undefined
            assertParamExists('createProjectsPvcV2', 'pVCCreationRequest', pVCCreationRequest)
            const localVarPath = `/api/v2/pvcs/{clusterUuid}/namespaces/{namespace}/{pvcName}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)))
                .replace(`{${"pvcName"}}`, encodeURIComponent(String(pvcName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pVCCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * this API is used by cluster versions < 2.17
         * @summary delete cluster-wide PVC
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} pvcName The pvc name.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deleteClusterWidePvcV1: async (clusterUuid: string, pvcName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('deleteClusterWidePvcV1', 'clusterUuid', clusterUuid)
            // verify required parameter 'pvcName' is not null or undefined
            assertParamExists('deleteClusterWidePvcV1', 'pvcName', pvcName)
            const localVarPath = `/api/v1/pvcs/{clusterUuid}/{pvcName}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"pvcName"}}`, encodeURIComponent(String(pvcName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete cluster-wide PVC
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} pvcName The pvc name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClusterWidePvcV2: async (clusterUuid: string, pvcName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('deleteClusterWidePvcV2', 'clusterUuid', clusterUuid)
            // verify required parameter 'pvcName' is not null or undefined
            assertParamExists('deleteClusterWidePvcV2', 'pvcName', pvcName)
            const localVarPath = `/api/v2/pvcs/{clusterUuid}/{pvcName}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"pvcName"}}`, encodeURIComponent(String(pvcName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * this API is used by cluster versions < 2.17
         * @summary delete department-wide PVC
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {string} pvcName The pvc name.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deleteDepartmentWidePvcV1: async (clusterUuid: string, departmentId: string, pvcName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('deleteDepartmentWidePvcV1', 'clusterUuid', clusterUuid)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('deleteDepartmentWidePvcV1', 'departmentId', departmentId)
            // verify required parameter 'pvcName' is not null or undefined
            assertParamExists('deleteDepartmentWidePvcV1', 'pvcName', pvcName)
            const localVarPath = `/api/v1/pvcs/{clusterUuid}/department/{departmentId}/{pvcName}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)))
                .replace(`{${"pvcName"}}`, encodeURIComponent(String(pvcName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete department-wide PVC
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {string} pvcName The pvc name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDepartmentWidePvcV2: async (clusterUuid: string, departmentId: string, pvcName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('deleteDepartmentWidePvcV2', 'clusterUuid', clusterUuid)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('deleteDepartmentWidePvcV2', 'departmentId', departmentId)
            // verify required parameter 'pvcName' is not null or undefined
            assertParamExists('deleteDepartmentWidePvcV2', 'pvcName', pvcName)
            const localVarPath = `/api/v2/pvcs/{clusterUuid}/department/{departmentId}/{pvcName}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)))
                .replace(`{${"pvcName"}}`, encodeURIComponent(String(pvcName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete department-wide PVC
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {string} pvcName The pvc name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDepartmentsWidePvcV2: async (clusterUuid: string, departmentId: string, pvcName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('deleteDepartmentsWidePvcV2', 'clusterUuid', clusterUuid)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('deleteDepartmentsWidePvcV2', 'departmentId', departmentId)
            // verify required parameter 'pvcName' is not null or undefined
            assertParamExists('deleteDepartmentsWidePvcV2', 'pvcName', pvcName)
            const localVarPath = `/api/v2/pvcs/{clusterUuid}/departments/{departmentId}/{pvcName}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)))
                .replace(`{${"pvcName"}}`, encodeURIComponent(String(pvcName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * this API is used by cluster versions < 2.17
         * @summary delete project PVC
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {string} pvcName The pvc name.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deleteProjectPvcV1: async (clusterUuid: string, namespace: string, pvcName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('deleteProjectPvcV1', 'clusterUuid', clusterUuid)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('deleteProjectPvcV1', 'namespace', namespace)
            // verify required parameter 'pvcName' is not null or undefined
            assertParamExists('deleteProjectPvcV1', 'pvcName', pvcName)
            const localVarPath = `/api/v1/pvcs/{clusterUuid}/namespace/{namespace}/{pvcName}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)))
                .replace(`{${"pvcName"}}`, encodeURIComponent(String(pvcName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete project PVC
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {string} pvcName The pvc name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectPvcV2: async (clusterUuid: string, namespace: string, pvcName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('deleteProjectPvcV2', 'clusterUuid', clusterUuid)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('deleteProjectPvcV2', 'namespace', namespace)
            // verify required parameter 'pvcName' is not null or undefined
            assertParamExists('deleteProjectPvcV2', 'pvcName', pvcName)
            const localVarPath = `/api/v2/pvcs/{clusterUuid}/namespace/{namespace}/{pvcName}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)))
                .replace(`{${"pvcName"}}`, encodeURIComponent(String(pvcName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete project PVC
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {string} pvcName The pvc name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectsPvcV2: async (clusterUuid: string, namespace: string, pvcName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('deleteProjectsPvcV2', 'clusterUuid', clusterUuid)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('deleteProjectsPvcV2', 'namespace', namespace)
            // verify required parameter 'pvcName' is not null or undefined
            assertParamExists('deleteProjectsPvcV2', 'pvcName', pvcName)
            const localVarPath = `/api/v2/pvcs/{clusterUuid}/namespaces/{namespace}/{pvcName}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)))
                .replace(`{${"pvcName"}}`, encodeURIComponent(String(pvcName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list details of pvcs
         * @param {string} [scopeType] The type of resource we want to filter by.
         * @param {string} [scopeId] filter by scope id
         * @param {string} [name] filter by name
         * @param {string} [clusterId] Filter using the Universally Unique Identifier (UUID) of the cluster.
         * @param {'all' | 'assets' | 'existing'} [origin] filter by the origin of the resource. by default, we have to list only customer existing resources (to preserve backward compatibility) to list both existing pvc and assets related pvcs use \&#39;all\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPvcs: async (scopeType?: string, scopeId?: string, name?: string, clusterId?: string, origin?: 'all' | 'assets' | 'existing', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/pvcs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (scopeType !== undefined) {
                localVarQueryParameter['scopeType'] = scopeType;
            }

            if (scopeId !== undefined) {
                localVarQueryParameter['scopeId'] = scopeId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }

            if (origin !== undefined) {
                localVarQueryParameter['origin'] = origin;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * this API is used by cluster versions < 2.15
         * @summary upload the details of PVC to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {SinglePVC} singlePVC 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obsoleteCreatePvc: async (clusterUuid: string, namespace: string, singlePVC: SinglePVC, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('obsoleteCreatePvc', 'clusterUuid', clusterUuid)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('obsoleteCreatePvc', 'namespace', namespace)
            // verify required parameter 'singlePVC' is not null or undefined
            assertParamExists('obsoleteCreatePvc', 'singlePVC', singlePVC)
            const localVarPath = `/v1/k8s/clusters/{clusterUuid}/{namespace}/pvcs`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(singlePVC, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * this API is used by cluster versions < 2.15
         * @summary delete PVC
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {string} pvcName The pvc name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obsoleteDeletePvc: async (clusterUuid: string, namespace: string, pvcName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('obsoleteDeletePvc', 'clusterUuid', clusterUuid)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('obsoleteDeletePvc', 'namespace', namespace)
            // verify required parameter 'pvcName' is not null or undefined
            assertParamExists('obsoleteDeletePvc', 'pvcName', pvcName)
            const localVarPath = `/v1/k8s/clusters/{clusterUuid}/{namespace}/pvcs/{pvcName}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)))
                .replace(`{${"pvcName"}}`, encodeURIComponent(String(pvcName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * this API is used by cluster versions < 2.15
         * @summary upload the details of PVCs to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {Array<SinglePVC>} singlePVC 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obsoleteSyncPvc: async (clusterUuid: string, namespace: string, singlePVC: Array<SinglePVC>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('obsoleteSyncPvc', 'clusterUuid', clusterUuid)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('obsoleteSyncPvc', 'namespace', namespace)
            // verify required parameter 'singlePVC' is not null or undefined
            assertParamExists('obsoleteSyncPvc', 'singlePVC', singlePVC)
            const localVarPath = `/v1/k8s/clusters/{clusterUuid}/{namespace}/pvcs/sync`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(singlePVC, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * this API is used by cluster versions < 2.17
         * @summary sync the names of cluster-wide pvcs to the control plane.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {PVCNames} pVCNames 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        syncClusterWidePvcNamesV1: async (clusterUuid: string, pVCNames: PVCNames, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('syncClusterWidePvcNamesV1', 'clusterUuid', clusterUuid)
            // verify required parameter 'pVCNames' is not null or undefined
            assertParamExists('syncClusterWidePvcNamesV1', 'pVCNames', pVCNames)
            const localVarPath = `/api/v1/pvcs/{clusterUuid}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pVCNames, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sync the details of cluster-wide pvcs to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {PVCs} pVCs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncClusterWidePvcsV2: async (clusterUuid: string, pVCs: PVCs, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('syncClusterWidePvcsV2', 'clusterUuid', clusterUuid)
            // verify required parameter 'pVCs' is not null or undefined
            assertParamExists('syncClusterWidePvcsV2', 'pVCs', pVCs)
            const localVarPath = `/api/v2/pvcs/{clusterUuid}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pVCs, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * this API is used by cluster versions < 2.17
         * @summary sync the names of department-wide pvcs to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {PVCNames} pVCNames 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        syncDepartmentWidePvcNamesV1: async (clusterUuid: string, departmentId: string, pVCNames: PVCNames, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('syncDepartmentWidePvcNamesV1', 'clusterUuid', clusterUuid)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('syncDepartmentWidePvcNamesV1', 'departmentId', departmentId)
            // verify required parameter 'pVCNames' is not null or undefined
            assertParamExists('syncDepartmentWidePvcNamesV1', 'pVCNames', pVCNames)
            const localVarPath = `/api/v1/pvcs/{clusterUuid}/department/{departmentId}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pVCNames, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sync the details of department-wide pvcs to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {PVCs} pVCs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncDepartmentWidePvcsV2: async (clusterUuid: string, departmentId: string, pVCs: PVCs, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('syncDepartmentWidePvcsV2', 'clusterUuid', clusterUuid)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('syncDepartmentWidePvcsV2', 'departmentId', departmentId)
            // verify required parameter 'pVCs' is not null or undefined
            assertParamExists('syncDepartmentWidePvcsV2', 'pVCs', pVCs)
            const localVarPath = `/api/v2/pvcs/{clusterUuid}/department/{departmentId}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pVCs, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sync the details of department-wide pvcs to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {PVCs} pVCs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncDepartmentsWidePvcsV2: async (clusterUuid: string, departmentId: string, pVCs: PVCs, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('syncDepartmentsWidePvcsV2', 'clusterUuid', clusterUuid)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('syncDepartmentsWidePvcsV2', 'departmentId', departmentId)
            // verify required parameter 'pVCs' is not null or undefined
            assertParamExists('syncDepartmentsWidePvcsV2', 'pVCs', pVCs)
            const localVarPath = `/api/v2/pvcs/{clusterUuid}/departments/{departmentId}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pVCs, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * this API is used by cluster versions < 2.17
         * @summary sync the names of project pvcs to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {PVCNames} pVCNames 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        syncProjectPvcNamesV1: async (clusterUuid: string, namespace: string, pVCNames: PVCNames, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('syncProjectPvcNamesV1', 'clusterUuid', clusterUuid)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('syncProjectPvcNamesV1', 'namespace', namespace)
            // verify required parameter 'pVCNames' is not null or undefined
            assertParamExists('syncProjectPvcNamesV1', 'pVCNames', pVCNames)
            const localVarPath = `/api/v1/pvcs/{clusterUuid}/namespace/{namespace}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pVCNames, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sync the details of project pvcs to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {PVCs} pVCs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncProjectPvcsV2: async (clusterUuid: string, namespace: string, pVCs: PVCs, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('syncProjectPvcsV2', 'clusterUuid', clusterUuid)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('syncProjectPvcsV2', 'namespace', namespace)
            // verify required parameter 'pVCs' is not null or undefined
            assertParamExists('syncProjectPvcsV2', 'pVCs', pVCs)
            const localVarPath = `/api/v2/pvcs/{clusterUuid}/namespace/{namespace}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pVCs, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sync the details of project pvcs to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {PVCs} pVCs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncProjectsPvcsV2: async (clusterUuid: string, namespace: string, pVCs: PVCs, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('syncProjectsPvcsV2', 'clusterUuid', clusterUuid)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('syncProjectsPvcsV2', 'namespace', namespace)
            // verify required parameter 'pVCs' is not null or undefined
            assertParamExists('syncProjectsPvcsV2', 'pVCs', pVCs)
            const localVarPath = `/api/v2/pvcs/{clusterUuid}/namespaces/{namespace}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pVCs, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PVCsApi - functional programming interface
 * @export
 */
export const PVCsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PVCsApiAxiosParamCreator(configuration)
    return {
        /**
         * this API is used by cluster versions < 2.17
         * @summary upload the name of cluster-wide pvc to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} pvcName The pvc name.
         * @param {CreateClusterWidePvcV1Request} [createClusterWidePvcV1Request] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async createClusterWidePvcV1(clusterUuid: string, pvcName: string, createClusterWidePvcV1Request?: CreateClusterWidePvcV1Request, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createClusterWidePvcV1(clusterUuid, pvcName, createClusterWidePvcV1Request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary upload the details of cluster-wide pvc to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} pvcName The pvc name.
         * @param {PVCCreationRequest} [pVCCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createClusterWidePvcV2(clusterUuid: string, pvcName: string, pVCCreationRequest?: PVCCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PVC>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createClusterWidePvcV2(clusterUuid, pvcName, pVCCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * this API is used by cluster versions < 2.17
         * @summary upload the name of department-wide pvc to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {string} pvcName The pvc name.
         * @param {CreateClusterWidePvcV1Request} [createClusterWidePvcV1Request] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async createDepartmentWidePvcV1(clusterUuid: string, departmentId: string, pvcName: string, createClusterWidePvcV1Request?: CreateClusterWidePvcV1Request, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDepartmentWidePvcV1(clusterUuid, departmentId, pvcName, createClusterWidePvcV1Request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary upload the details of department-wide pvc to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {string} pvcName The pvc name.
         * @param {PVCCreationRequest} pVCCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDepartmentWidePvcV2(clusterUuid: string, departmentId: string, pvcName: string, pVCCreationRequest: PVCCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PVC>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDepartmentWidePvcV2(clusterUuid, departmentId, pvcName, pVCCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary upload the details of department-wide pvc to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {string} pvcName The pvc name.
         * @param {PVCCreationRequest} pVCCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDepartmentsWidePvcV2(clusterUuid: string, departmentId: string, pvcName: string, pVCCreationRequest: PVCCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PVC>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDepartmentsWidePvcV2(clusterUuid, departmentId, pvcName, pVCCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * this API is used by cluster versions < 2.17
         * @summary upload the name of project pvc to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {string} pvcName The pvc name.
         * @param {CreateClusterWidePvcV1Request} [createClusterWidePvcV1Request] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async createProjectPvcV1(clusterUuid: string, namespace: string, pvcName: string, createClusterWidePvcV1Request?: CreateClusterWidePvcV1Request, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProjectPvcV1(clusterUuid, namespace, pvcName, createClusterWidePvcV1Request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary upload the details of project pvc to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {string} pvcName The pvc name.
         * @param {PVCCreationRequest} pVCCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProjectPvcV2(clusterUuid: string, namespace: string, pvcName: string, pVCCreationRequest: PVCCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PVC>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProjectPvcV2(clusterUuid, namespace, pvcName, pVCCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary upload the details of project pvc to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {string} pvcName The pvc name.
         * @param {PVCCreationRequest} pVCCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProjectsPvcV2(clusterUuid: string, namespace: string, pvcName: string, pVCCreationRequest: PVCCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PVC>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProjectsPvcV2(clusterUuid, namespace, pvcName, pVCCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * this API is used by cluster versions < 2.17
         * @summary delete cluster-wide PVC
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} pvcName The pvc name.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async deleteClusterWidePvcV1(clusterUuid: string, pvcName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteClusterWidePvcV1(clusterUuid, pvcName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete cluster-wide PVC
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} pvcName The pvc name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteClusterWidePvcV2(clusterUuid: string, pvcName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteClusterWidePvcV2(clusterUuid, pvcName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * this API is used by cluster versions < 2.17
         * @summary delete department-wide PVC
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {string} pvcName The pvc name.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async deleteDepartmentWidePvcV1(clusterUuid: string, departmentId: string, pvcName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDepartmentWidePvcV1(clusterUuid, departmentId, pvcName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete department-wide PVC
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {string} pvcName The pvc name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDepartmentWidePvcV2(clusterUuid: string, departmentId: string, pvcName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDepartmentWidePvcV2(clusterUuid, departmentId, pvcName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete department-wide PVC
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {string} pvcName The pvc name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDepartmentsWidePvcV2(clusterUuid: string, departmentId: string, pvcName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDepartmentsWidePvcV2(clusterUuid, departmentId, pvcName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * this API is used by cluster versions < 2.17
         * @summary delete project PVC
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {string} pvcName The pvc name.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async deleteProjectPvcV1(clusterUuid: string, namespace: string, pvcName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProjectPvcV1(clusterUuid, namespace, pvcName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete project PVC
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {string} pvcName The pvc name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProjectPvcV2(clusterUuid: string, namespace: string, pvcName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProjectPvcV2(clusterUuid, namespace, pvcName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete project PVC
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {string} pvcName The pvc name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProjectsPvcV2(clusterUuid: string, namespace: string, pvcName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProjectsPvcV2(clusterUuid, namespace, pvcName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list details of pvcs
         * @param {string} [scopeType] The type of resource we want to filter by.
         * @param {string} [scopeId] filter by scope id
         * @param {string} [name] filter by name
         * @param {string} [clusterId] Filter using the Universally Unique Identifier (UUID) of the cluster.
         * @param {'all' | 'assets' | 'existing'} [origin] filter by the origin of the resource. by default, we have to list only customer existing resources (to preserve backward compatibility) to list both existing pvc and assets related pvcs use \&#39;all\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPvcs(scopeType?: string, scopeId?: string, name?: string, clusterId?: string, origin?: 'all' | 'assets' | 'existing', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PVCListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPvcs(scopeType, scopeId, name, clusterId, origin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * this API is used by cluster versions < 2.15
         * @summary upload the details of PVC to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {SinglePVC} singlePVC 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obsoleteCreatePvc(clusterUuid: string, namespace: string, singlePVC: SinglePVC, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SinglePVC>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obsoleteCreatePvc(clusterUuid, namespace, singlePVC, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * this API is used by cluster versions < 2.15
         * @summary delete PVC
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {string} pvcName The pvc name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obsoleteDeletePvc(clusterUuid: string, namespace: string, pvcName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obsoleteDeletePvc(clusterUuid, namespace, pvcName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * this API is used by cluster versions < 2.15
         * @summary upload the details of PVCs to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {Array<SinglePVC>} singlePVC 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obsoleteSyncPvc(clusterUuid: string, namespace: string, singlePVC: Array<SinglePVC>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SinglePVC>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obsoleteSyncPvc(clusterUuid, namespace, singlePVC, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * this API is used by cluster versions < 2.17
         * @summary sync the names of cluster-wide pvcs to the control plane.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {PVCNames} pVCNames 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async syncClusterWidePvcNamesV1(clusterUuid: string, pVCNames: PVCNames, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PVCNames>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncClusterWidePvcNamesV1(clusterUuid, pVCNames, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary sync the details of cluster-wide pvcs to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {PVCs} pVCs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncClusterWidePvcsV2(clusterUuid: string, pVCs: PVCs, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PVCs>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncClusterWidePvcsV2(clusterUuid, pVCs, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * this API is used by cluster versions < 2.17
         * @summary sync the names of department-wide pvcs to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {PVCNames} pVCNames 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async syncDepartmentWidePvcNamesV1(clusterUuid: string, departmentId: string, pVCNames: PVCNames, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PVCNames>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncDepartmentWidePvcNamesV1(clusterUuid, departmentId, pVCNames, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary sync the details of department-wide pvcs to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {PVCs} pVCs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncDepartmentWidePvcsV2(clusterUuid: string, departmentId: string, pVCs: PVCs, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PVCs>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncDepartmentWidePvcsV2(clusterUuid, departmentId, pVCs, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary sync the details of department-wide pvcs to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {PVCs} pVCs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncDepartmentsWidePvcsV2(clusterUuid: string, departmentId: string, pVCs: PVCs, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PVCs>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncDepartmentsWidePvcsV2(clusterUuid, departmentId, pVCs, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * this API is used by cluster versions < 2.17
         * @summary sync the names of project pvcs to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {PVCNames} pVCNames 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async syncProjectPvcNamesV1(clusterUuid: string, namespace: string, pVCNames: PVCNames, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PVCNames>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncProjectPvcNamesV1(clusterUuid, namespace, pVCNames, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary sync the details of project pvcs to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {PVCs} pVCs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncProjectPvcsV2(clusterUuid: string, namespace: string, pVCs: PVCs, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PVCs>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncProjectPvcsV2(clusterUuid, namespace, pVCs, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary sync the details of project pvcs to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {PVCs} pVCs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncProjectsPvcsV2(clusterUuid: string, namespace: string, pVCs: PVCs, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PVCs>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncProjectsPvcsV2(clusterUuid, namespace, pVCs, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PVCsApi - factory interface
 * @export
 */
export const PVCsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PVCsApiFp(configuration)
    return {
        /**
         * this API is used by cluster versions < 2.17
         * @summary upload the name of cluster-wide pvc to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} pvcName The pvc name.
         * @param {CreateClusterWidePvcV1Request} [createClusterWidePvcV1Request] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createClusterWidePvcV1(clusterUuid: string, pvcName: string, createClusterWidePvcV1Request?: CreateClusterWidePvcV1Request, options?: any): AxiosPromise<string> {
            return localVarFp.createClusterWidePvcV1(clusterUuid, pvcName, createClusterWidePvcV1Request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary upload the details of cluster-wide pvc to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} pvcName The pvc name.
         * @param {PVCCreationRequest} [pVCCreationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClusterWidePvcV2(clusterUuid: string, pvcName: string, pVCCreationRequest?: PVCCreationRequest, options?: any): AxiosPromise<PVC> {
            return localVarFp.createClusterWidePvcV2(clusterUuid, pvcName, pVCCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * this API is used by cluster versions < 2.17
         * @summary upload the name of department-wide pvc to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {string} pvcName The pvc name.
         * @param {CreateClusterWidePvcV1Request} [createClusterWidePvcV1Request] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createDepartmentWidePvcV1(clusterUuid: string, departmentId: string, pvcName: string, createClusterWidePvcV1Request?: CreateClusterWidePvcV1Request, options?: any): AxiosPromise<string> {
            return localVarFp.createDepartmentWidePvcV1(clusterUuid, departmentId, pvcName, createClusterWidePvcV1Request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary upload the details of department-wide pvc to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {string} pvcName The pvc name.
         * @param {PVCCreationRequest} pVCCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDepartmentWidePvcV2(clusterUuid: string, departmentId: string, pvcName: string, pVCCreationRequest: PVCCreationRequest, options?: any): AxiosPromise<PVC> {
            return localVarFp.createDepartmentWidePvcV2(clusterUuid, departmentId, pvcName, pVCCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary upload the details of department-wide pvc to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {string} pvcName The pvc name.
         * @param {PVCCreationRequest} pVCCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDepartmentsWidePvcV2(clusterUuid: string, departmentId: string, pvcName: string, pVCCreationRequest: PVCCreationRequest, options?: any): AxiosPromise<PVC> {
            return localVarFp.createDepartmentsWidePvcV2(clusterUuid, departmentId, pvcName, pVCCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * this API is used by cluster versions < 2.17
         * @summary upload the name of project pvc to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {string} pvcName The pvc name.
         * @param {CreateClusterWidePvcV1Request} [createClusterWidePvcV1Request] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createProjectPvcV1(clusterUuid: string, namespace: string, pvcName: string, createClusterWidePvcV1Request?: CreateClusterWidePvcV1Request, options?: any): AxiosPromise<string> {
            return localVarFp.createProjectPvcV1(clusterUuid, namespace, pvcName, createClusterWidePvcV1Request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary upload the details of project pvc to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {string} pvcName The pvc name.
         * @param {PVCCreationRequest} pVCCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProjectPvcV2(clusterUuid: string, namespace: string, pvcName: string, pVCCreationRequest: PVCCreationRequest, options?: any): AxiosPromise<PVC> {
            return localVarFp.createProjectPvcV2(clusterUuid, namespace, pvcName, pVCCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary upload the details of project pvc to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {string} pvcName The pvc name.
         * @param {PVCCreationRequest} pVCCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProjectsPvcV2(clusterUuid: string, namespace: string, pvcName: string, pVCCreationRequest: PVCCreationRequest, options?: any): AxiosPromise<PVC> {
            return localVarFp.createProjectsPvcV2(clusterUuid, namespace, pvcName, pVCCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * this API is used by cluster versions < 2.17
         * @summary delete cluster-wide PVC
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} pvcName The pvc name.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deleteClusterWidePvcV1(clusterUuid: string, pvcName: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteClusterWidePvcV1(clusterUuid, pvcName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete cluster-wide PVC
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} pvcName The pvc name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClusterWidePvcV2(clusterUuid: string, pvcName: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteClusterWidePvcV2(clusterUuid, pvcName, options).then((request) => request(axios, basePath));
        },
        /**
         * this API is used by cluster versions < 2.17
         * @summary delete department-wide PVC
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {string} pvcName The pvc name.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deleteDepartmentWidePvcV1(clusterUuid: string, departmentId: string, pvcName: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDepartmentWidePvcV1(clusterUuid, departmentId, pvcName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete department-wide PVC
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {string} pvcName The pvc name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDepartmentWidePvcV2(clusterUuid: string, departmentId: string, pvcName: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDepartmentWidePvcV2(clusterUuid, departmentId, pvcName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete department-wide PVC
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {string} pvcName The pvc name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDepartmentsWidePvcV2(clusterUuid: string, departmentId: string, pvcName: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDepartmentsWidePvcV2(clusterUuid, departmentId, pvcName, options).then((request) => request(axios, basePath));
        },
        /**
         * this API is used by cluster versions < 2.17
         * @summary delete project PVC
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {string} pvcName The pvc name.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deleteProjectPvcV1(clusterUuid: string, namespace: string, pvcName: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProjectPvcV1(clusterUuid, namespace, pvcName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete project PVC
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {string} pvcName The pvc name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectPvcV2(clusterUuid: string, namespace: string, pvcName: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProjectPvcV2(clusterUuid, namespace, pvcName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete project PVC
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {string} pvcName The pvc name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectsPvcV2(clusterUuid: string, namespace: string, pvcName: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProjectsPvcV2(clusterUuid, namespace, pvcName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list details of pvcs
         * @param {string} [scopeType] The type of resource we want to filter by.
         * @param {string} [scopeId] filter by scope id
         * @param {string} [name] filter by name
         * @param {string} [clusterId] Filter using the Universally Unique Identifier (UUID) of the cluster.
         * @param {'all' | 'assets' | 'existing'} [origin] filter by the origin of the resource. by default, we have to list only customer existing resources (to preserve backward compatibility) to list both existing pvc and assets related pvcs use \&#39;all\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPvcs(scopeType?: string, scopeId?: string, name?: string, clusterId?: string, origin?: 'all' | 'assets' | 'existing', options?: any): AxiosPromise<PVCListResponse> {
            return localVarFp.listPvcs(scopeType, scopeId, name, clusterId, origin, options).then((request) => request(axios, basePath));
        },
        /**
         * this API is used by cluster versions < 2.15
         * @summary upload the details of PVC to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {SinglePVC} singlePVC 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obsoleteCreatePvc(clusterUuid: string, namespace: string, singlePVC: SinglePVC, options?: any): AxiosPromise<SinglePVC> {
            return localVarFp.obsoleteCreatePvc(clusterUuid, namespace, singlePVC, options).then((request) => request(axios, basePath));
        },
        /**
         * this API is used by cluster versions < 2.15
         * @summary delete PVC
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {string} pvcName The pvc name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obsoleteDeletePvc(clusterUuid: string, namespace: string, pvcName: string, options?: any): AxiosPromise<void> {
            return localVarFp.obsoleteDeletePvc(clusterUuid, namespace, pvcName, options).then((request) => request(axios, basePath));
        },
        /**
         * this API is used by cluster versions < 2.15
         * @summary upload the details of PVCs to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {Array<SinglePVC>} singlePVC 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obsoleteSyncPvc(clusterUuid: string, namespace: string, singlePVC: Array<SinglePVC>, options?: any): AxiosPromise<Array<SinglePVC>> {
            return localVarFp.obsoleteSyncPvc(clusterUuid, namespace, singlePVC, options).then((request) => request(axios, basePath));
        },
        /**
         * this API is used by cluster versions < 2.17
         * @summary sync the names of cluster-wide pvcs to the control plane.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {PVCNames} pVCNames 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        syncClusterWidePvcNamesV1(clusterUuid: string, pVCNames: PVCNames, options?: any): AxiosPromise<PVCNames> {
            return localVarFp.syncClusterWidePvcNamesV1(clusterUuid, pVCNames, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sync the details of cluster-wide pvcs to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {PVCs} pVCs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncClusterWidePvcsV2(clusterUuid: string, pVCs: PVCs, options?: any): AxiosPromise<PVCs> {
            return localVarFp.syncClusterWidePvcsV2(clusterUuid, pVCs, options).then((request) => request(axios, basePath));
        },
        /**
         * this API is used by cluster versions < 2.17
         * @summary sync the names of department-wide pvcs to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {PVCNames} pVCNames 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        syncDepartmentWidePvcNamesV1(clusterUuid: string, departmentId: string, pVCNames: PVCNames, options?: any): AxiosPromise<PVCNames> {
            return localVarFp.syncDepartmentWidePvcNamesV1(clusterUuid, departmentId, pVCNames, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sync the details of department-wide pvcs to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {PVCs} pVCs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncDepartmentWidePvcsV2(clusterUuid: string, departmentId: string, pVCs: PVCs, options?: any): AxiosPromise<PVCs> {
            return localVarFp.syncDepartmentWidePvcsV2(clusterUuid, departmentId, pVCs, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sync the details of department-wide pvcs to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {PVCs} pVCs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncDepartmentsWidePvcsV2(clusterUuid: string, departmentId: string, pVCs: PVCs, options?: any): AxiosPromise<PVCs> {
            return localVarFp.syncDepartmentsWidePvcsV2(clusterUuid, departmentId, pVCs, options).then((request) => request(axios, basePath));
        },
        /**
         * this API is used by cluster versions < 2.17
         * @summary sync the names of project pvcs to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {PVCNames} pVCNames 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        syncProjectPvcNamesV1(clusterUuid: string, namespace: string, pVCNames: PVCNames, options?: any): AxiosPromise<PVCNames> {
            return localVarFp.syncProjectPvcNamesV1(clusterUuid, namespace, pVCNames, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sync the details of project pvcs to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {PVCs} pVCs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncProjectPvcsV2(clusterUuid: string, namespace: string, pVCs: PVCs, options?: any): AxiosPromise<PVCs> {
            return localVarFp.syncProjectPvcsV2(clusterUuid, namespace, pVCs, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sync the details of project pvcs to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {PVCs} pVCs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncProjectsPvcsV2(clusterUuid: string, namespace: string, pVCs: PVCs, options?: any): AxiosPromise<PVCs> {
            return localVarFp.syncProjectsPvcsV2(clusterUuid, namespace, pVCs, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PVCsApi - object-oriented interface
 * @export
 * @class PVCsApi
 * @extends {BaseAPI}
 */
export class PVCsApi extends BaseAPI {
    /**
     * this API is used by cluster versions < 2.17
     * @summary upload the name of cluster-wide pvc to the control plane
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} pvcName The pvc name.
     * @param {CreateClusterWidePvcV1Request} [createClusterWidePvcV1Request] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PVCsApi
     */
    public createClusterWidePvcV1(clusterUuid: string, pvcName: string, createClusterWidePvcV1Request?: CreateClusterWidePvcV1Request, options?: AxiosRequestConfig) {
        return PVCsApiFp(this.configuration).createClusterWidePvcV1(clusterUuid, pvcName, createClusterWidePvcV1Request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary upload the details of cluster-wide pvc to the control plane
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} pvcName The pvc name.
     * @param {PVCCreationRequest} [pVCCreationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PVCsApi
     */
    public createClusterWidePvcV2(clusterUuid: string, pvcName: string, pVCCreationRequest?: PVCCreationRequest, options?: AxiosRequestConfig) {
        return PVCsApiFp(this.configuration).createClusterWidePvcV2(clusterUuid, pvcName, pVCCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * this API is used by cluster versions < 2.17
     * @summary upload the name of department-wide pvc to the control plane
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} departmentId The id of the department.
     * @param {string} pvcName The pvc name.
     * @param {CreateClusterWidePvcV1Request} [createClusterWidePvcV1Request] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PVCsApi
     */
    public createDepartmentWidePvcV1(clusterUuid: string, departmentId: string, pvcName: string, createClusterWidePvcV1Request?: CreateClusterWidePvcV1Request, options?: AxiosRequestConfig) {
        return PVCsApiFp(this.configuration).createDepartmentWidePvcV1(clusterUuid, departmentId, pvcName, createClusterWidePvcV1Request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary upload the details of department-wide pvc to the control plane
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} departmentId The id of the department.
     * @param {string} pvcName The pvc name.
     * @param {PVCCreationRequest} pVCCreationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PVCsApi
     */
    public createDepartmentWidePvcV2(clusterUuid: string, departmentId: string, pvcName: string, pVCCreationRequest: PVCCreationRequest, options?: AxiosRequestConfig) {
        return PVCsApiFp(this.configuration).createDepartmentWidePvcV2(clusterUuid, departmentId, pvcName, pVCCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary upload the details of department-wide pvc to the control plane
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} departmentId The id of the department.
     * @param {string} pvcName The pvc name.
     * @param {PVCCreationRequest} pVCCreationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PVCsApi
     */
    public createDepartmentsWidePvcV2(clusterUuid: string, departmentId: string, pvcName: string, pVCCreationRequest: PVCCreationRequest, options?: AxiosRequestConfig) {
        return PVCsApiFp(this.configuration).createDepartmentsWidePvcV2(clusterUuid, departmentId, pvcName, pVCCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * this API is used by cluster versions < 2.17
     * @summary upload the name of project pvc to the control plane
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} namespace The namespace of the project.
     * @param {string} pvcName The pvc name.
     * @param {CreateClusterWidePvcV1Request} [createClusterWidePvcV1Request] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PVCsApi
     */
    public createProjectPvcV1(clusterUuid: string, namespace: string, pvcName: string, createClusterWidePvcV1Request?: CreateClusterWidePvcV1Request, options?: AxiosRequestConfig) {
        return PVCsApiFp(this.configuration).createProjectPvcV1(clusterUuid, namespace, pvcName, createClusterWidePvcV1Request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary upload the details of project pvc to the control plane
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} namespace The namespace of the project.
     * @param {string} pvcName The pvc name.
     * @param {PVCCreationRequest} pVCCreationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PVCsApi
     */
    public createProjectPvcV2(clusterUuid: string, namespace: string, pvcName: string, pVCCreationRequest: PVCCreationRequest, options?: AxiosRequestConfig) {
        return PVCsApiFp(this.configuration).createProjectPvcV2(clusterUuid, namespace, pvcName, pVCCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary upload the details of project pvc to the control plane
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} namespace The namespace of the project.
     * @param {string} pvcName The pvc name.
     * @param {PVCCreationRequest} pVCCreationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PVCsApi
     */
    public createProjectsPvcV2(clusterUuid: string, namespace: string, pvcName: string, pVCCreationRequest: PVCCreationRequest, options?: AxiosRequestConfig) {
        return PVCsApiFp(this.configuration).createProjectsPvcV2(clusterUuid, namespace, pvcName, pVCCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * this API is used by cluster versions < 2.17
     * @summary delete cluster-wide PVC
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} pvcName The pvc name.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PVCsApi
     */
    public deleteClusterWidePvcV1(clusterUuid: string, pvcName: string, options?: AxiosRequestConfig) {
        return PVCsApiFp(this.configuration).deleteClusterWidePvcV1(clusterUuid, pvcName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete cluster-wide PVC
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} pvcName The pvc name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PVCsApi
     */
    public deleteClusterWidePvcV2(clusterUuid: string, pvcName: string, options?: AxiosRequestConfig) {
        return PVCsApiFp(this.configuration).deleteClusterWidePvcV2(clusterUuid, pvcName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * this API is used by cluster versions < 2.17
     * @summary delete department-wide PVC
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} departmentId The id of the department.
     * @param {string} pvcName The pvc name.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PVCsApi
     */
    public deleteDepartmentWidePvcV1(clusterUuid: string, departmentId: string, pvcName: string, options?: AxiosRequestConfig) {
        return PVCsApiFp(this.configuration).deleteDepartmentWidePvcV1(clusterUuid, departmentId, pvcName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete department-wide PVC
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} departmentId The id of the department.
     * @param {string} pvcName The pvc name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PVCsApi
     */
    public deleteDepartmentWidePvcV2(clusterUuid: string, departmentId: string, pvcName: string, options?: AxiosRequestConfig) {
        return PVCsApiFp(this.configuration).deleteDepartmentWidePvcV2(clusterUuid, departmentId, pvcName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete department-wide PVC
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} departmentId The id of the department.
     * @param {string} pvcName The pvc name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PVCsApi
     */
    public deleteDepartmentsWidePvcV2(clusterUuid: string, departmentId: string, pvcName: string, options?: AxiosRequestConfig) {
        return PVCsApiFp(this.configuration).deleteDepartmentsWidePvcV2(clusterUuid, departmentId, pvcName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * this API is used by cluster versions < 2.17
     * @summary delete project PVC
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} namespace The namespace of the project.
     * @param {string} pvcName The pvc name.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PVCsApi
     */
    public deleteProjectPvcV1(clusterUuid: string, namespace: string, pvcName: string, options?: AxiosRequestConfig) {
        return PVCsApiFp(this.configuration).deleteProjectPvcV1(clusterUuid, namespace, pvcName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete project PVC
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} namespace The namespace of the project.
     * @param {string} pvcName The pvc name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PVCsApi
     */
    public deleteProjectPvcV2(clusterUuid: string, namespace: string, pvcName: string, options?: AxiosRequestConfig) {
        return PVCsApiFp(this.configuration).deleteProjectPvcV2(clusterUuid, namespace, pvcName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete project PVC
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} namespace The namespace of the project.
     * @param {string} pvcName The pvc name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PVCsApi
     */
    public deleteProjectsPvcV2(clusterUuid: string, namespace: string, pvcName: string, options?: AxiosRequestConfig) {
        return PVCsApiFp(this.configuration).deleteProjectsPvcV2(clusterUuid, namespace, pvcName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list details of pvcs
     * @param {string} [scopeType] The type of resource we want to filter by.
     * @param {string} [scopeId] filter by scope id
     * @param {string} [name] filter by name
     * @param {string} [clusterId] Filter using the Universally Unique Identifier (UUID) of the cluster.
     * @param {'all' | 'assets' | 'existing'} [origin] filter by the origin of the resource. by default, we have to list only customer existing resources (to preserve backward compatibility) to list both existing pvc and assets related pvcs use \&#39;all\&#39;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PVCsApi
     */
    public listPvcs(scopeType?: string, scopeId?: string, name?: string, clusterId?: string, origin?: 'all' | 'assets' | 'existing', options?: AxiosRequestConfig) {
        return PVCsApiFp(this.configuration).listPvcs(scopeType, scopeId, name, clusterId, origin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * this API is used by cluster versions < 2.15
     * @summary upload the details of PVC to the control plane
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} namespace The namespace of the project.
     * @param {SinglePVC} singlePVC 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PVCsApi
     */
    public obsoleteCreatePvc(clusterUuid: string, namespace: string, singlePVC: SinglePVC, options?: AxiosRequestConfig) {
        return PVCsApiFp(this.configuration).obsoleteCreatePvc(clusterUuid, namespace, singlePVC, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * this API is used by cluster versions < 2.15
     * @summary delete PVC
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} namespace The namespace of the project.
     * @param {string} pvcName The pvc name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PVCsApi
     */
    public obsoleteDeletePvc(clusterUuid: string, namespace: string, pvcName: string, options?: AxiosRequestConfig) {
        return PVCsApiFp(this.configuration).obsoleteDeletePvc(clusterUuid, namespace, pvcName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * this API is used by cluster versions < 2.15
     * @summary upload the details of PVCs to the control plane
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} namespace The namespace of the project.
     * @param {Array<SinglePVC>} singlePVC 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PVCsApi
     */
    public obsoleteSyncPvc(clusterUuid: string, namespace: string, singlePVC: Array<SinglePVC>, options?: AxiosRequestConfig) {
        return PVCsApiFp(this.configuration).obsoleteSyncPvc(clusterUuid, namespace, singlePVC, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * this API is used by cluster versions < 2.17
     * @summary sync the names of cluster-wide pvcs to the control plane.
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {PVCNames} pVCNames 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PVCsApi
     */
    public syncClusterWidePvcNamesV1(clusterUuid: string, pVCNames: PVCNames, options?: AxiosRequestConfig) {
        return PVCsApiFp(this.configuration).syncClusterWidePvcNamesV1(clusterUuid, pVCNames, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sync the details of cluster-wide pvcs to the control plane
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {PVCs} pVCs 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PVCsApi
     */
    public syncClusterWidePvcsV2(clusterUuid: string, pVCs: PVCs, options?: AxiosRequestConfig) {
        return PVCsApiFp(this.configuration).syncClusterWidePvcsV2(clusterUuid, pVCs, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * this API is used by cluster versions < 2.17
     * @summary sync the names of department-wide pvcs to the control plane
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} departmentId The id of the department.
     * @param {PVCNames} pVCNames 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PVCsApi
     */
    public syncDepartmentWidePvcNamesV1(clusterUuid: string, departmentId: string, pVCNames: PVCNames, options?: AxiosRequestConfig) {
        return PVCsApiFp(this.configuration).syncDepartmentWidePvcNamesV1(clusterUuid, departmentId, pVCNames, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sync the details of department-wide pvcs to the control plane
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} departmentId The id of the department.
     * @param {PVCs} pVCs 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PVCsApi
     */
    public syncDepartmentWidePvcsV2(clusterUuid: string, departmentId: string, pVCs: PVCs, options?: AxiosRequestConfig) {
        return PVCsApiFp(this.configuration).syncDepartmentWidePvcsV2(clusterUuid, departmentId, pVCs, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sync the details of department-wide pvcs to the control plane
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} departmentId The id of the department.
     * @param {PVCs} pVCs 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PVCsApi
     */
    public syncDepartmentsWidePvcsV2(clusterUuid: string, departmentId: string, pVCs: PVCs, options?: AxiosRequestConfig) {
        return PVCsApiFp(this.configuration).syncDepartmentsWidePvcsV2(clusterUuid, departmentId, pVCs, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * this API is used by cluster versions < 2.17
     * @summary sync the names of project pvcs to the control plane
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} namespace The namespace of the project.
     * @param {PVCNames} pVCNames 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PVCsApi
     */
    public syncProjectPvcNamesV1(clusterUuid: string, namespace: string, pVCNames: PVCNames, options?: AxiosRequestConfig) {
        return PVCsApiFp(this.configuration).syncProjectPvcNamesV1(clusterUuid, namespace, pVCNames, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sync the details of project pvcs to the control plane
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} namespace The namespace of the project.
     * @param {PVCs} pVCs 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PVCsApi
     */
    public syncProjectPvcsV2(clusterUuid: string, namespace: string, pVCs: PVCs, options?: AxiosRequestConfig) {
        return PVCsApiFp(this.configuration).syncProjectPvcsV2(clusterUuid, namespace, pVCs, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sync the details of project pvcs to the control plane
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} namespace The namespace of the project.
     * @param {PVCs} pVCs 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PVCsApi
     */
    public syncProjectsPvcsV2(clusterUuid: string, namespace: string, pVCs: PVCs, options?: AxiosRequestConfig) {
        return PVCsApiFp(this.configuration).syncProjectsPvcsV2(clusterUuid, namespace, pVCs, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SecretsApi - axios parameter creator
 * @export
 */
export const SecretsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary upload the details of cluster-wide secret to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} secretName The secret name.
         * @param {SecretCreationRequest} secretCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClusterWideSecret: async (clusterUuid: string, secretName: string, secretCreationRequest: SecretCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('createClusterWideSecret', 'clusterUuid', clusterUuid)
            // verify required parameter 'secretName' is not null or undefined
            assertParamExists('createClusterWideSecret', 'secretName', secretName)
            // verify required parameter 'secretCreationRequest' is not null or undefined
            assertParamExists('createClusterWideSecret', 'secretCreationRequest', secretCreationRequest)
            const localVarPath = `/api/v1/secrets/{clusterUuid}/{secretName}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"secretName"}}`, encodeURIComponent(String(secretName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(secretCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary upload the details of department-wide secret to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {string} secretName The secret name.
         * @param {SecretCreationRequest} secretCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDepartmentWideSecret: async (clusterUuid: string, departmentId: string, secretName: string, secretCreationRequest: SecretCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('createDepartmentWideSecret', 'clusterUuid', clusterUuid)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('createDepartmentWideSecret', 'departmentId', departmentId)
            // verify required parameter 'secretName' is not null or undefined
            assertParamExists('createDepartmentWideSecret', 'secretName', secretName)
            // verify required parameter 'secretCreationRequest' is not null or undefined
            assertParamExists('createDepartmentWideSecret', 'secretCreationRequest', secretCreationRequest)
            const localVarPath = `/api/v1/secrets/{clusterUuid}/department/{departmentId}/{secretName}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)))
                .replace(`{${"secretName"}}`, encodeURIComponent(String(secretName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(secretCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary upload the details of department-wide secret to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {string} secretName The secret name.
         * @param {SecretCreationRequest} secretCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDepartmentsWideSecret: async (clusterUuid: string, departmentId: string, secretName: string, secretCreationRequest: SecretCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('createDepartmentsWideSecret', 'clusterUuid', clusterUuid)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('createDepartmentsWideSecret', 'departmentId', departmentId)
            // verify required parameter 'secretName' is not null or undefined
            assertParamExists('createDepartmentsWideSecret', 'secretName', secretName)
            // verify required parameter 'secretCreationRequest' is not null or undefined
            assertParamExists('createDepartmentsWideSecret', 'secretCreationRequest', secretCreationRequest)
            const localVarPath = `/api/v1/secrets/{clusterUuid}/departments/{departmentId}/{secretName}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)))
                .replace(`{${"secretName"}}`, encodeURIComponent(String(secretName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(secretCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary upload the details of project-wide secret to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {string} secretName The secret name.
         * @param {SecretCreationRequest} secretCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProjectSecret: async (clusterUuid: string, namespace: string, secretName: string, secretCreationRequest: SecretCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('createProjectSecret', 'clusterUuid', clusterUuid)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('createProjectSecret', 'namespace', namespace)
            // verify required parameter 'secretName' is not null or undefined
            assertParamExists('createProjectSecret', 'secretName', secretName)
            // verify required parameter 'secretCreationRequest' is not null or undefined
            assertParamExists('createProjectSecret', 'secretCreationRequest', secretCreationRequest)
            const localVarPath = `/api/v1/secrets/{clusterUuid}/namespace/{namespace}/{secretName}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)))
                .replace(`{${"secretName"}}`, encodeURIComponent(String(secretName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(secretCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary upload the details of project-wide secret to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {string} secretName The secret name.
         * @param {SecretCreationRequest} secretCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProjectsSecret: async (clusterUuid: string, namespace: string, secretName: string, secretCreationRequest: SecretCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('createProjectsSecret', 'clusterUuid', clusterUuid)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('createProjectsSecret', 'namespace', namespace)
            // verify required parameter 'secretName' is not null or undefined
            assertParamExists('createProjectsSecret', 'secretName', secretName)
            // verify required parameter 'secretCreationRequest' is not null or undefined
            assertParamExists('createProjectsSecret', 'secretCreationRequest', secretCreationRequest)
            const localVarPath = `/api/v1/secrets/{clusterUuid}/namespaces/{namespace}/{secretName}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)))
                .replace(`{${"secretName"}}`, encodeURIComponent(String(secretName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(secretCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete cluster-wide secret
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} secretName The secret name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClusterWideSecret: async (clusterUuid: string, secretName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('deleteClusterWideSecret', 'clusterUuid', clusterUuid)
            // verify required parameter 'secretName' is not null or undefined
            assertParamExists('deleteClusterWideSecret', 'secretName', secretName)
            const localVarPath = `/api/v1/secrets/{clusterUuid}/{secretName}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"secretName"}}`, encodeURIComponent(String(secretName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete department-wide secret
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {string} secretName The secret name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDepartmentWideSecret: async (clusterUuid: string, departmentId: string, secretName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('deleteDepartmentWideSecret', 'clusterUuid', clusterUuid)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('deleteDepartmentWideSecret', 'departmentId', departmentId)
            // verify required parameter 'secretName' is not null or undefined
            assertParamExists('deleteDepartmentWideSecret', 'secretName', secretName)
            const localVarPath = `/api/v1/secrets/{clusterUuid}/department/{departmentId}/{secretName}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)))
                .replace(`{${"secretName"}}`, encodeURIComponent(String(secretName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete department-wide secret
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {string} secretName The secret name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDepartmentsWideSecret: async (clusterUuid: string, departmentId: string, secretName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('deleteDepartmentsWideSecret', 'clusterUuid', clusterUuid)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('deleteDepartmentsWideSecret', 'departmentId', departmentId)
            // verify required parameter 'secretName' is not null or undefined
            assertParamExists('deleteDepartmentsWideSecret', 'secretName', secretName)
            const localVarPath = `/api/v1/secrets/{clusterUuid}/departments/{departmentId}/{secretName}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)))
                .replace(`{${"secretName"}}`, encodeURIComponent(String(secretName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete project secret
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {string} secretName The secret name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectSecret: async (clusterUuid: string, namespace: string, secretName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('deleteProjectSecret', 'clusterUuid', clusterUuid)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('deleteProjectSecret', 'namespace', namespace)
            // verify required parameter 'secretName' is not null or undefined
            assertParamExists('deleteProjectSecret', 'secretName', secretName)
            const localVarPath = `/api/v1/secrets/{clusterUuid}/namespace/{namespace}/{secretName}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)))
                .replace(`{${"secretName"}}`, encodeURIComponent(String(secretName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete project secret
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {string} secretName The secret name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectsSecret: async (clusterUuid: string, namespace: string, secretName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('deleteProjectsSecret', 'clusterUuid', clusterUuid)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('deleteProjectsSecret', 'namespace', namespace)
            // verify required parameter 'secretName' is not null or undefined
            assertParamExists('deleteProjectsSecret', 'secretName', secretName)
            const localVarPath = `/api/v1/secrets/{clusterUuid}/namespaces/{namespace}/{secretName}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)))
                .replace(`{${"secretName"}}`, encodeURIComponent(String(secretName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list details of secrets
         * @param {string} [scopeType] The type of resource we want to filter by.
         * @param {string} [scopeId] filter by scope id
         * @param {string} [name] filter by name
         * @param {string} [secretType] filter by secret type. supported values - password, access-key, docker-registry.
         * @param {string} [clusterId] Filter using the Universally Unique Identifier (UUID) of the cluster.
         * @param {'all' | 'assets' | 'existing'} [origin] filter by the origin of the resource. by default, we have to list only customer existing resources (to preserve backward compatibility) to list both existing pvc and assets related pvcs use \&#39;all\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSecrets: async (scopeType?: string, scopeId?: string, name?: string, secretType?: string, clusterId?: string, origin?: 'all' | 'assets' | 'existing', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/secrets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (scopeType !== undefined) {
                localVarQueryParameter['scopeType'] = scopeType;
            }

            if (scopeId !== undefined) {
                localVarQueryParameter['scopeId'] = scopeId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (secretType !== undefined) {
                localVarQueryParameter['secretType'] = secretType;
            }

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }

            if (origin !== undefined) {
                localVarQueryParameter['origin'] = origin;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * this API is used by cluster versions < 2.15
         * @summary upload the details of a secret to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {SingleSecret} singleSecret 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obsoleteCreateSecret: async (clusterUuid: string, namespace: string, singleSecret: SingleSecret, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('obsoleteCreateSecret', 'clusterUuid', clusterUuid)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('obsoleteCreateSecret', 'namespace', namespace)
            // verify required parameter 'singleSecret' is not null or undefined
            assertParamExists('obsoleteCreateSecret', 'singleSecret', singleSecret)
            const localVarPath = `/v1/k8s/clusters/{clusterUuid}/{namespace}/secrets`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(singleSecret, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * this API is used by cluster versions < 2.15
         * @summary delete secret
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {string} secretName The secret name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obsoleteDeleteSecret: async (clusterUuid: string, namespace: string, secretName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('obsoleteDeleteSecret', 'clusterUuid', clusterUuid)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('obsoleteDeleteSecret', 'namespace', namespace)
            // verify required parameter 'secretName' is not null or undefined
            assertParamExists('obsoleteDeleteSecret', 'secretName', secretName)
            const localVarPath = `/v1/k8s/clusters/{clusterUuid}/{namespace}/secrets/{secretName}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)))
                .replace(`{${"secretName"}}`, encodeURIComponent(String(secretName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * this API is used by cluster versions < 2.15
         * @summary upload the details of secrets to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {Array<SingleSecret>} singleSecret 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obsoleteSyncSecret: async (clusterUuid: string, namespace: string, singleSecret: Array<SingleSecret>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('obsoleteSyncSecret', 'clusterUuid', clusterUuid)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('obsoleteSyncSecret', 'namespace', namespace)
            // verify required parameter 'singleSecret' is not null or undefined
            assertParamExists('obsoleteSyncSecret', 'singleSecret', singleSecret)
            const localVarPath = `/v1/k8s/clusters/{clusterUuid}/{namespace}/secrets/sync`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(singleSecret, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sync the details of cluster-wide secrets to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {Secrets} secrets 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncClusterWideSecrets: async (clusterUuid: string, secrets: Secrets, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('syncClusterWideSecrets', 'clusterUuid', clusterUuid)
            // verify required parameter 'secrets' is not null or undefined
            assertParamExists('syncClusterWideSecrets', 'secrets', secrets)
            const localVarPath = `/api/v1/secrets/{clusterUuid}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(secrets, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sync the details of department-wide secrets to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {Secrets} secrets 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncDepartmentWideSecrets: async (clusterUuid: string, departmentId: string, secrets: Secrets, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('syncDepartmentWideSecrets', 'clusterUuid', clusterUuid)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('syncDepartmentWideSecrets', 'departmentId', departmentId)
            // verify required parameter 'secrets' is not null or undefined
            assertParamExists('syncDepartmentWideSecrets', 'secrets', secrets)
            const localVarPath = `/api/v1/secrets/{clusterUuid}/department/{departmentId}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(secrets, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sync the details of department-wide secrets to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {Secrets} secrets 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncDepartmentsWideSecrets: async (clusterUuid: string, departmentId: string, secrets: Secrets, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('syncDepartmentsWideSecrets', 'clusterUuid', clusterUuid)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('syncDepartmentsWideSecrets', 'departmentId', departmentId)
            // verify required parameter 'secrets' is not null or undefined
            assertParamExists('syncDepartmentsWideSecrets', 'secrets', secrets)
            const localVarPath = `/api/v1/secrets/{clusterUuid}/departments/{departmentId}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(secrets, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sync the details of project secrets to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {Secrets} secrets 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncProjectSecrets: async (clusterUuid: string, namespace: string, secrets: Secrets, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('syncProjectSecrets', 'clusterUuid', clusterUuid)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('syncProjectSecrets', 'namespace', namespace)
            // verify required parameter 'secrets' is not null or undefined
            assertParamExists('syncProjectSecrets', 'secrets', secrets)
            const localVarPath = `/api/v1/secrets/{clusterUuid}/namespace/{namespace}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(secrets, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sync the details of project secrets to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {Secrets} secrets 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncProjectsSecrets: async (clusterUuid: string, namespace: string, secrets: Secrets, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('syncProjectsSecrets', 'clusterUuid', clusterUuid)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('syncProjectsSecrets', 'namespace', namespace)
            // verify required parameter 'secrets' is not null or undefined
            assertParamExists('syncProjectsSecrets', 'secrets', secrets)
            const localVarPath = `/api/v1/secrets/{clusterUuid}/namespaces/{namespace}`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(secrets, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SecretsApi - functional programming interface
 * @export
 */
export const SecretsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SecretsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary upload the details of cluster-wide secret to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} secretName The secret name.
         * @param {SecretCreationRequest} secretCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createClusterWideSecret(clusterUuid: string, secretName: string, secretCreationRequest: SecretCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecretCreationRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createClusterWideSecret(clusterUuid, secretName, secretCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary upload the details of department-wide secret to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {string} secretName The secret name.
         * @param {SecretCreationRequest} secretCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDepartmentWideSecret(clusterUuid: string, departmentId: string, secretName: string, secretCreationRequest: SecretCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Secret>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDepartmentWideSecret(clusterUuid, departmentId, secretName, secretCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary upload the details of department-wide secret to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {string} secretName The secret name.
         * @param {SecretCreationRequest} secretCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDepartmentsWideSecret(clusterUuid: string, departmentId: string, secretName: string, secretCreationRequest: SecretCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Secret>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDepartmentsWideSecret(clusterUuid, departmentId, secretName, secretCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary upload the details of project-wide secret to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {string} secretName The secret name.
         * @param {SecretCreationRequest} secretCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProjectSecret(clusterUuid: string, namespace: string, secretName: string, secretCreationRequest: SecretCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Secret>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProjectSecret(clusterUuid, namespace, secretName, secretCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary upload the details of project-wide secret to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {string} secretName The secret name.
         * @param {SecretCreationRequest} secretCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProjectsSecret(clusterUuid: string, namespace: string, secretName: string, secretCreationRequest: SecretCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Secret>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProjectsSecret(clusterUuid, namespace, secretName, secretCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete cluster-wide secret
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} secretName The secret name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteClusterWideSecret(clusterUuid: string, secretName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteClusterWideSecret(clusterUuid, secretName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete department-wide secret
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {string} secretName The secret name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDepartmentWideSecret(clusterUuid: string, departmentId: string, secretName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDepartmentWideSecret(clusterUuid, departmentId, secretName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete department-wide secret
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {string} secretName The secret name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDepartmentsWideSecret(clusterUuid: string, departmentId: string, secretName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDepartmentsWideSecret(clusterUuid, departmentId, secretName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete project secret
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {string} secretName The secret name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProjectSecret(clusterUuid: string, namespace: string, secretName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProjectSecret(clusterUuid, namespace, secretName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete project secret
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {string} secretName The secret name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProjectsSecret(clusterUuid: string, namespace: string, secretName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProjectsSecret(clusterUuid, namespace, secretName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list details of secrets
         * @param {string} [scopeType] The type of resource we want to filter by.
         * @param {string} [scopeId] filter by scope id
         * @param {string} [name] filter by name
         * @param {string} [secretType] filter by secret type. supported values - password, access-key, docker-registry.
         * @param {string} [clusterId] Filter using the Universally Unique Identifier (UUID) of the cluster.
         * @param {'all' | 'assets' | 'existing'} [origin] filter by the origin of the resource. by default, we have to list only customer existing resources (to preserve backward compatibility) to list both existing pvc and assets related pvcs use \&#39;all\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSecrets(scopeType?: string, scopeId?: string, name?: string, secretType?: string, clusterId?: string, origin?: 'all' | 'assets' | 'existing', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecretListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSecrets(scopeType, scopeId, name, secretType, clusterId, origin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * this API is used by cluster versions < 2.15
         * @summary upload the details of a secret to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {SingleSecret} singleSecret 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obsoleteCreateSecret(clusterUuid: string, namespace: string, singleSecret: SingleSecret, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SingleSecret>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obsoleteCreateSecret(clusterUuid, namespace, singleSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * this API is used by cluster versions < 2.15
         * @summary delete secret
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {string} secretName The secret name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obsoleteDeleteSecret(clusterUuid: string, namespace: string, secretName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obsoleteDeleteSecret(clusterUuid, namespace, secretName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * this API is used by cluster versions < 2.15
         * @summary upload the details of secrets to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {Array<SingleSecret>} singleSecret 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obsoleteSyncSecret(clusterUuid: string, namespace: string, singleSecret: Array<SingleSecret>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SingleSecret>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obsoleteSyncSecret(clusterUuid, namespace, singleSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary sync the details of cluster-wide secrets to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {Secrets} secrets 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncClusterWideSecrets(clusterUuid: string, secrets: Secrets, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Secrets>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncClusterWideSecrets(clusterUuid, secrets, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary sync the details of department-wide secrets to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {Secrets} secrets 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncDepartmentWideSecrets(clusterUuid: string, departmentId: string, secrets: Secrets, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Secrets>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncDepartmentWideSecrets(clusterUuid, departmentId, secrets, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary sync the details of department-wide secrets to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {Secrets} secrets 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncDepartmentsWideSecrets(clusterUuid: string, departmentId: string, secrets: Secrets, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Secrets>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncDepartmentsWideSecrets(clusterUuid, departmentId, secrets, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary sync the details of project secrets to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {Secrets} secrets 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncProjectSecrets(clusterUuid: string, namespace: string, secrets: Secrets, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Secrets>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncProjectSecrets(clusterUuid, namespace, secrets, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary sync the details of project secrets to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {Secrets} secrets 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncProjectsSecrets(clusterUuid: string, namespace: string, secrets: Secrets, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Secrets>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncProjectsSecrets(clusterUuid, namespace, secrets, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SecretsApi - factory interface
 * @export
 */
export const SecretsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SecretsApiFp(configuration)
    return {
        /**
         * 
         * @summary upload the details of cluster-wide secret to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} secretName The secret name.
         * @param {SecretCreationRequest} secretCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClusterWideSecret(clusterUuid: string, secretName: string, secretCreationRequest: SecretCreationRequest, options?: any): AxiosPromise<SecretCreationRequest> {
            return localVarFp.createClusterWideSecret(clusterUuid, secretName, secretCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary upload the details of department-wide secret to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {string} secretName The secret name.
         * @param {SecretCreationRequest} secretCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDepartmentWideSecret(clusterUuid: string, departmentId: string, secretName: string, secretCreationRequest: SecretCreationRequest, options?: any): AxiosPromise<Secret> {
            return localVarFp.createDepartmentWideSecret(clusterUuid, departmentId, secretName, secretCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary upload the details of department-wide secret to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {string} secretName The secret name.
         * @param {SecretCreationRequest} secretCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDepartmentsWideSecret(clusterUuid: string, departmentId: string, secretName: string, secretCreationRequest: SecretCreationRequest, options?: any): AxiosPromise<Secret> {
            return localVarFp.createDepartmentsWideSecret(clusterUuid, departmentId, secretName, secretCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary upload the details of project-wide secret to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {string} secretName The secret name.
         * @param {SecretCreationRequest} secretCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProjectSecret(clusterUuid: string, namespace: string, secretName: string, secretCreationRequest: SecretCreationRequest, options?: any): AxiosPromise<Secret> {
            return localVarFp.createProjectSecret(clusterUuid, namespace, secretName, secretCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary upload the details of project-wide secret to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {string} secretName The secret name.
         * @param {SecretCreationRequest} secretCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProjectsSecret(clusterUuid: string, namespace: string, secretName: string, secretCreationRequest: SecretCreationRequest, options?: any): AxiosPromise<Secret> {
            return localVarFp.createProjectsSecret(clusterUuid, namespace, secretName, secretCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete cluster-wide secret
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} secretName The secret name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClusterWideSecret(clusterUuid: string, secretName: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteClusterWideSecret(clusterUuid, secretName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete department-wide secret
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {string} secretName The secret name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDepartmentWideSecret(clusterUuid: string, departmentId: string, secretName: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDepartmentWideSecret(clusterUuid, departmentId, secretName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete department-wide secret
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {string} secretName The secret name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDepartmentsWideSecret(clusterUuid: string, departmentId: string, secretName: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDepartmentsWideSecret(clusterUuid, departmentId, secretName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete project secret
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {string} secretName The secret name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectSecret(clusterUuid: string, namespace: string, secretName: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProjectSecret(clusterUuid, namespace, secretName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete project secret
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {string} secretName The secret name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectsSecret(clusterUuid: string, namespace: string, secretName: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProjectsSecret(clusterUuid, namespace, secretName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list details of secrets
         * @param {string} [scopeType] The type of resource we want to filter by.
         * @param {string} [scopeId] filter by scope id
         * @param {string} [name] filter by name
         * @param {string} [secretType] filter by secret type. supported values - password, access-key, docker-registry.
         * @param {string} [clusterId] Filter using the Universally Unique Identifier (UUID) of the cluster.
         * @param {'all' | 'assets' | 'existing'} [origin] filter by the origin of the resource. by default, we have to list only customer existing resources (to preserve backward compatibility) to list both existing pvc and assets related pvcs use \&#39;all\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSecrets(scopeType?: string, scopeId?: string, name?: string, secretType?: string, clusterId?: string, origin?: 'all' | 'assets' | 'existing', options?: any): AxiosPromise<SecretListResponse> {
            return localVarFp.listSecrets(scopeType, scopeId, name, secretType, clusterId, origin, options).then((request) => request(axios, basePath));
        },
        /**
         * this API is used by cluster versions < 2.15
         * @summary upload the details of a secret to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {SingleSecret} singleSecret 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obsoleteCreateSecret(clusterUuid: string, namespace: string, singleSecret: SingleSecret, options?: any): AxiosPromise<SingleSecret> {
            return localVarFp.obsoleteCreateSecret(clusterUuid, namespace, singleSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * this API is used by cluster versions < 2.15
         * @summary delete secret
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {string} secretName The secret name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obsoleteDeleteSecret(clusterUuid: string, namespace: string, secretName: string, options?: any): AxiosPromise<void> {
            return localVarFp.obsoleteDeleteSecret(clusterUuid, namespace, secretName, options).then((request) => request(axios, basePath));
        },
        /**
         * this API is used by cluster versions < 2.15
         * @summary upload the details of secrets to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {Array<SingleSecret>} singleSecret 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obsoleteSyncSecret(clusterUuid: string, namespace: string, singleSecret: Array<SingleSecret>, options?: any): AxiosPromise<Array<SingleSecret>> {
            return localVarFp.obsoleteSyncSecret(clusterUuid, namespace, singleSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sync the details of cluster-wide secrets to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {Secrets} secrets 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncClusterWideSecrets(clusterUuid: string, secrets: Secrets, options?: any): AxiosPromise<Secrets> {
            return localVarFp.syncClusterWideSecrets(clusterUuid, secrets, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sync the details of department-wide secrets to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {Secrets} secrets 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncDepartmentWideSecrets(clusterUuid: string, departmentId: string, secrets: Secrets, options?: any): AxiosPromise<Secrets> {
            return localVarFp.syncDepartmentWideSecrets(clusterUuid, departmentId, secrets, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sync the details of department-wide secrets to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {Secrets} secrets 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncDepartmentsWideSecrets(clusterUuid: string, departmentId: string, secrets: Secrets, options?: any): AxiosPromise<Secrets> {
            return localVarFp.syncDepartmentsWideSecrets(clusterUuid, departmentId, secrets, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sync the details of project secrets to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {Secrets} secrets 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncProjectSecrets(clusterUuid: string, namespace: string, secrets: Secrets, options?: any): AxiosPromise<Secrets> {
            return localVarFp.syncProjectSecrets(clusterUuid, namespace, secrets, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sync the details of project secrets to the control plane
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} namespace The namespace of the project.
         * @param {Secrets} secrets 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncProjectsSecrets(clusterUuid: string, namespace: string, secrets: Secrets, options?: any): AxiosPromise<Secrets> {
            return localVarFp.syncProjectsSecrets(clusterUuid, namespace, secrets, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SecretsApi - object-oriented interface
 * @export
 * @class SecretsApi
 * @extends {BaseAPI}
 */
export class SecretsApi extends BaseAPI {
    /**
     * 
     * @summary upload the details of cluster-wide secret to the control plane
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} secretName The secret name.
     * @param {SecretCreationRequest} secretCreationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecretsApi
     */
    public createClusterWideSecret(clusterUuid: string, secretName: string, secretCreationRequest: SecretCreationRequest, options?: AxiosRequestConfig) {
        return SecretsApiFp(this.configuration).createClusterWideSecret(clusterUuid, secretName, secretCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary upload the details of department-wide secret to the control plane
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} departmentId The id of the department.
     * @param {string} secretName The secret name.
     * @param {SecretCreationRequest} secretCreationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecretsApi
     */
    public createDepartmentWideSecret(clusterUuid: string, departmentId: string, secretName: string, secretCreationRequest: SecretCreationRequest, options?: AxiosRequestConfig) {
        return SecretsApiFp(this.configuration).createDepartmentWideSecret(clusterUuid, departmentId, secretName, secretCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary upload the details of department-wide secret to the control plane
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} departmentId The id of the department.
     * @param {string} secretName The secret name.
     * @param {SecretCreationRequest} secretCreationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecretsApi
     */
    public createDepartmentsWideSecret(clusterUuid: string, departmentId: string, secretName: string, secretCreationRequest: SecretCreationRequest, options?: AxiosRequestConfig) {
        return SecretsApiFp(this.configuration).createDepartmentsWideSecret(clusterUuid, departmentId, secretName, secretCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary upload the details of project-wide secret to the control plane
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} namespace The namespace of the project.
     * @param {string} secretName The secret name.
     * @param {SecretCreationRequest} secretCreationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecretsApi
     */
    public createProjectSecret(clusterUuid: string, namespace: string, secretName: string, secretCreationRequest: SecretCreationRequest, options?: AxiosRequestConfig) {
        return SecretsApiFp(this.configuration).createProjectSecret(clusterUuid, namespace, secretName, secretCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary upload the details of project-wide secret to the control plane
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} namespace The namespace of the project.
     * @param {string} secretName The secret name.
     * @param {SecretCreationRequest} secretCreationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecretsApi
     */
    public createProjectsSecret(clusterUuid: string, namespace: string, secretName: string, secretCreationRequest: SecretCreationRequest, options?: AxiosRequestConfig) {
        return SecretsApiFp(this.configuration).createProjectsSecret(clusterUuid, namespace, secretName, secretCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete cluster-wide secret
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} secretName The secret name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecretsApi
     */
    public deleteClusterWideSecret(clusterUuid: string, secretName: string, options?: AxiosRequestConfig) {
        return SecretsApiFp(this.configuration).deleteClusterWideSecret(clusterUuid, secretName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete department-wide secret
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} departmentId The id of the department.
     * @param {string} secretName The secret name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecretsApi
     */
    public deleteDepartmentWideSecret(clusterUuid: string, departmentId: string, secretName: string, options?: AxiosRequestConfig) {
        return SecretsApiFp(this.configuration).deleteDepartmentWideSecret(clusterUuid, departmentId, secretName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete department-wide secret
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} departmentId The id of the department.
     * @param {string} secretName The secret name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecretsApi
     */
    public deleteDepartmentsWideSecret(clusterUuid: string, departmentId: string, secretName: string, options?: AxiosRequestConfig) {
        return SecretsApiFp(this.configuration).deleteDepartmentsWideSecret(clusterUuid, departmentId, secretName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete project secret
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} namespace The namespace of the project.
     * @param {string} secretName The secret name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecretsApi
     */
    public deleteProjectSecret(clusterUuid: string, namespace: string, secretName: string, options?: AxiosRequestConfig) {
        return SecretsApiFp(this.configuration).deleteProjectSecret(clusterUuid, namespace, secretName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete project secret
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} namespace The namespace of the project.
     * @param {string} secretName The secret name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecretsApi
     */
    public deleteProjectsSecret(clusterUuid: string, namespace: string, secretName: string, options?: AxiosRequestConfig) {
        return SecretsApiFp(this.configuration).deleteProjectsSecret(clusterUuid, namespace, secretName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list details of secrets
     * @param {string} [scopeType] The type of resource we want to filter by.
     * @param {string} [scopeId] filter by scope id
     * @param {string} [name] filter by name
     * @param {string} [secretType] filter by secret type. supported values - password, access-key, docker-registry.
     * @param {string} [clusterId] Filter using the Universally Unique Identifier (UUID) of the cluster.
     * @param {'all' | 'assets' | 'existing'} [origin] filter by the origin of the resource. by default, we have to list only customer existing resources (to preserve backward compatibility) to list both existing pvc and assets related pvcs use \&#39;all\&#39;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecretsApi
     */
    public listSecrets(scopeType?: string, scopeId?: string, name?: string, secretType?: string, clusterId?: string, origin?: 'all' | 'assets' | 'existing', options?: AxiosRequestConfig) {
        return SecretsApiFp(this.configuration).listSecrets(scopeType, scopeId, name, secretType, clusterId, origin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * this API is used by cluster versions < 2.15
     * @summary upload the details of a secret to the control plane
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} namespace The namespace of the project.
     * @param {SingleSecret} singleSecret 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecretsApi
     */
    public obsoleteCreateSecret(clusterUuid: string, namespace: string, singleSecret: SingleSecret, options?: AxiosRequestConfig) {
        return SecretsApiFp(this.configuration).obsoleteCreateSecret(clusterUuid, namespace, singleSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * this API is used by cluster versions < 2.15
     * @summary delete secret
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} namespace The namespace of the project.
     * @param {string} secretName The secret name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecretsApi
     */
    public obsoleteDeleteSecret(clusterUuid: string, namespace: string, secretName: string, options?: AxiosRequestConfig) {
        return SecretsApiFp(this.configuration).obsoleteDeleteSecret(clusterUuid, namespace, secretName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * this API is used by cluster versions < 2.15
     * @summary upload the details of secrets to the control plane
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} namespace The namespace of the project.
     * @param {Array<SingleSecret>} singleSecret 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecretsApi
     */
    public obsoleteSyncSecret(clusterUuid: string, namespace: string, singleSecret: Array<SingleSecret>, options?: AxiosRequestConfig) {
        return SecretsApiFp(this.configuration).obsoleteSyncSecret(clusterUuid, namespace, singleSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sync the details of cluster-wide secrets to the control plane
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {Secrets} secrets 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecretsApi
     */
    public syncClusterWideSecrets(clusterUuid: string, secrets: Secrets, options?: AxiosRequestConfig) {
        return SecretsApiFp(this.configuration).syncClusterWideSecrets(clusterUuid, secrets, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sync the details of department-wide secrets to the control plane
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} departmentId The id of the department.
     * @param {Secrets} secrets 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecretsApi
     */
    public syncDepartmentWideSecrets(clusterUuid: string, departmentId: string, secrets: Secrets, options?: AxiosRequestConfig) {
        return SecretsApiFp(this.configuration).syncDepartmentWideSecrets(clusterUuid, departmentId, secrets, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sync the details of department-wide secrets to the control plane
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} departmentId The id of the department.
     * @param {Secrets} secrets 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecretsApi
     */
    public syncDepartmentsWideSecrets(clusterUuid: string, departmentId: string, secrets: Secrets, options?: AxiosRequestConfig) {
        return SecretsApiFp(this.configuration).syncDepartmentsWideSecrets(clusterUuid, departmentId, secrets, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sync the details of project secrets to the control plane
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} namespace The namespace of the project.
     * @param {Secrets} secrets 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecretsApi
     */
    public syncProjectSecrets(clusterUuid: string, namespace: string, secrets: Secrets, options?: AxiosRequestConfig) {
        return SecretsApiFp(this.configuration).syncProjectSecrets(clusterUuid, namespace, secrets, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sync the details of project secrets to the control plane
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} namespace The namespace of the project.
     * @param {Secrets} secrets 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecretsApi
     */
    public syncProjectsSecrets(clusterUuid: string, namespace: string, secrets: Secrets, options?: AxiosRequestConfig) {
        return SecretsApiFp(this.configuration).syncProjectsSecrets(clusterUuid, namespace, secrets, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StorageClassesApi - axios parameter creator
 * @export
 */
export const StorageClassesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create a Storage Class
         * @param {StorageClass} storageClass 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStorageClass: async (storageClass: StorageClass, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storageClass' is not null or undefined
            assertParamExists('createStorageClass', 'storageClass', storageClass)
            const localVarPath = `/api/v1/storage-classes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storageClass, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete a Storage Class
         * @param {string} clusterId The id of the cluster
         * @param {string} name filter by name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStorageClass: async (clusterId: string, name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('deleteStorageClass', 'clusterId', clusterId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('deleteStorageClass', 'name', name)
            const localVarPath = `/api/v1/storage-classes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a Storage Class/Classes for a given cluster
         * @param {string} clusterId The id of the cluster
         * @param {string} [name] filter by name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStorageClasses: async (clusterId: string, name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('getStorageClasses', 'clusterId', clusterId)
            const localVarPath = `/api/v1/storage-classes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use to sync cluster storage classes
         * @summary Sync cluster Storage Classes
         * @param {string} clusterId The id of the cluster
         * @param {StorageClasses} storageClasses 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncStorageClass: async (clusterId: string, storageClasses: StorageClasses, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('syncStorageClass', 'clusterId', clusterId)
            // verify required parameter 'storageClasses' is not null or undefined
            assertParamExists('syncStorageClass', 'storageClasses', storageClasses)
            const localVarPath = `/api/v1/storage-classes/sync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storageClasses, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use to create a storageClass object in a cluster using the cluster\'s Universally Unique Identifier (UUID).
         * @summary Create a storageClass.
         * @param {string} uuid Unique identifier of the cluster
         * @param {V1StorageClass} v1StorageClass 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CreateStorageClass: async (uuid: string, v1StorageClass: V1StorageClass, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('v1CreateStorageClass', 'uuid', uuid)
            // verify required parameter 'v1StorageClass' is not null or undefined
            assertParamExists('v1CreateStorageClass', 'v1StorageClass', v1StorageClass)
            const localVarPath = `/v1/k8s/clusters/{uuid}/storage-classes`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1StorageClass, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use to delete a storageClass by Universally Unique Identifier (UUID).
         * @summary Delete a storageClass.
         * @param {string} uuid Unique identifier of the cluster
         * @param {string} storageClassName Unique identifier of the storageClass
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DeleteStorageClass: async (uuid: string, storageClassName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('v1DeleteStorageClass', 'uuid', uuid)
            // verify required parameter 'storageClassName' is not null or undefined
            assertParamExists('v1DeleteStorageClass', 'storageClassName', storageClassName)
            const localVarPath = `/v1/k8s/clusters/{uuid}/storage-classes/{storage_class_name}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)))
                .replace(`{${"storage_class_name"}}`, encodeURIComponent(String(storageClassName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a list of storageClass names by Universally Unique Identifier (UUID) of the cluster.
         * @summary Get all storageClasses from a cluster.
         * @param {string} uuid Unique identifier of the cluster
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GetStorageClasses: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('v1GetStorageClasses', 'uuid', uuid)
            const localVarPath = `/v1/k8s/clusters/{uuid}/storage-classes`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use to sync the storageClass to a cluster.
         * @summary Sync storageClass to a cluster.
         * @param {string} uuid Unique identifier of the cluster
         * @param {Array<V1StorageClass>} v1StorageClass 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SyncStorageClass: async (uuid: string, v1StorageClass: Array<V1StorageClass>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('v1SyncStorageClass', 'uuid', uuid)
            // verify required parameter 'v1StorageClass' is not null or undefined
            assertParamExists('v1SyncStorageClass', 'v1StorageClass', v1StorageClass)
            const localVarPath = `/v1/k8s/clusters/{uuid}/storage-classes/sync`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1StorageClass, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use to update the details of a storageClass.
         * @summary Update a storageClass.
         * @param {string} uuid Unique identifier of the cluster
         * @param {string} storageClassName Unique identifier of the storageClass
         * @param {V1StorageClass} v1StorageClass 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UpdateStorageClass: async (uuid: string, storageClassName: string, v1StorageClass: V1StorageClass, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('v1UpdateStorageClass', 'uuid', uuid)
            // verify required parameter 'storageClassName' is not null or undefined
            assertParamExists('v1UpdateStorageClass', 'storageClassName', storageClassName)
            // verify required parameter 'v1StorageClass' is not null or undefined
            assertParamExists('v1UpdateStorageClass', 'v1StorageClass', v1StorageClass)
            const localVarPath = `/v1/k8s/clusters/{uuid}/storage-classes/{storage_class_name}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)))
                .replace(`{${"storage_class_name"}}`, encodeURIComponent(String(storageClassName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v1StorageClass, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StorageClassesApi - functional programming interface
 * @export
 */
export const StorageClassesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StorageClassesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create a Storage Class
         * @param {StorageClass} storageClass 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createStorageClass(storageClass: StorageClass, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorageClass>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createStorageClass(storageClass, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete a Storage Class
         * @param {string} clusterId The id of the cluster
         * @param {string} name filter by name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteStorageClass(clusterId: string, name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteStorageClass(clusterId, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get a Storage Class/Classes for a given cluster
         * @param {string} clusterId The id of the cluster
         * @param {string} [name] filter by name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStorageClasses(clusterId: string, name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorageClasses>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStorageClasses(clusterId, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use to sync cluster storage classes
         * @summary Sync cluster Storage Classes
         * @param {string} clusterId The id of the cluster
         * @param {StorageClasses} storageClasses 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncStorageClass(clusterId: string, storageClasses: StorageClasses, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorageClasses>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncStorageClass(clusterId, storageClasses, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use to create a storageClass object in a cluster using the cluster\'s Universally Unique Identifier (UUID).
         * @summary Create a storageClass.
         * @param {string} uuid Unique identifier of the cluster
         * @param {V1StorageClass} v1StorageClass 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1CreateStorageClass(uuid: string, v1StorageClass: V1StorageClass, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1StorageClass>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1CreateStorageClass(uuid, v1StorageClass, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use to delete a storageClass by Universally Unique Identifier (UUID).
         * @summary Delete a storageClass.
         * @param {string} uuid Unique identifier of the cluster
         * @param {string} storageClassName Unique identifier of the storageClass
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DeleteStorageClass(uuid: string, storageClassName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DeleteStorageClass(uuid, storageClassName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a list of storageClass names by Universally Unique Identifier (UUID) of the cluster.
         * @summary Get all storageClasses from a cluster.
         * @param {string} uuid Unique identifier of the cluster
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1GetStorageClasses(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<V1StorageClass>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1GetStorageClasses(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use to sync the storageClass to a cluster.
         * @summary Sync storageClass to a cluster.
         * @param {string} uuid Unique identifier of the cluster
         * @param {Array<V1StorageClass>} v1StorageClass 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1SyncStorageClass(uuid: string, v1StorageClass: Array<V1StorageClass>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V1StorageClass>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1SyncStorageClass(uuid, v1StorageClass, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use to update the details of a storageClass.
         * @summary Update a storageClass.
         * @param {string} uuid Unique identifier of the cluster
         * @param {string} storageClassName Unique identifier of the storageClass
         * @param {V1StorageClass} v1StorageClass 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UpdateStorageClass(uuid: string, storageClassName: string, v1StorageClass: V1StorageClass, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<V1StorageClass>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1UpdateStorageClass(uuid, storageClassName, v1StorageClass, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StorageClassesApi - factory interface
 * @export
 */
export const StorageClassesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StorageClassesApiFp(configuration)
    return {
        /**
         * 
         * @summary create a Storage Class
         * @param {StorageClass} storageClass 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStorageClass(storageClass: StorageClass, options?: any): AxiosPromise<StorageClass> {
            return localVarFp.createStorageClass(storageClass, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete a Storage Class
         * @param {string} clusterId The id of the cluster
         * @param {string} name filter by name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStorageClass(clusterId: string, name: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteStorageClass(clusterId, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get a Storage Class/Classes for a given cluster
         * @param {string} clusterId The id of the cluster
         * @param {string} [name] filter by name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStorageClasses(clusterId: string, name?: string, options?: any): AxiosPromise<StorageClasses> {
            return localVarFp.getStorageClasses(clusterId, name, options).then((request) => request(axios, basePath));
        },
        /**
         * Use to sync cluster storage classes
         * @summary Sync cluster Storage Classes
         * @param {string} clusterId The id of the cluster
         * @param {StorageClasses} storageClasses 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncStorageClass(clusterId: string, storageClasses: StorageClasses, options?: any): AxiosPromise<StorageClasses> {
            return localVarFp.syncStorageClass(clusterId, storageClasses, options).then((request) => request(axios, basePath));
        },
        /**
         * Use to create a storageClass object in a cluster using the cluster\'s Universally Unique Identifier (UUID).
         * @summary Create a storageClass.
         * @param {string} uuid Unique identifier of the cluster
         * @param {V1StorageClass} v1StorageClass 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CreateStorageClass(uuid: string, v1StorageClass: V1StorageClass, options?: any): AxiosPromise<V1StorageClass> {
            return localVarFp.v1CreateStorageClass(uuid, v1StorageClass, options).then((request) => request(axios, basePath));
        },
        /**
         * Use to delete a storageClass by Universally Unique Identifier (UUID).
         * @summary Delete a storageClass.
         * @param {string} uuid Unique identifier of the cluster
         * @param {string} storageClassName Unique identifier of the storageClass
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DeleteStorageClass(uuid: string, storageClassName: string, options?: any): AxiosPromise<void> {
            return localVarFp.v1DeleteStorageClass(uuid, storageClassName, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a list of storageClass names by Universally Unique Identifier (UUID) of the cluster.
         * @summary Get all storageClasses from a cluster.
         * @param {string} uuid Unique identifier of the cluster
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GetStorageClasses(uuid: string, options?: any): AxiosPromise<Array<V1StorageClass>> {
            return localVarFp.v1GetStorageClasses(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Use to sync the storageClass to a cluster.
         * @summary Sync storageClass to a cluster.
         * @param {string} uuid Unique identifier of the cluster
         * @param {Array<V1StorageClass>} v1StorageClass 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SyncStorageClass(uuid: string, v1StorageClass: Array<V1StorageClass>, options?: any): AxiosPromise<V1StorageClass> {
            return localVarFp.v1SyncStorageClass(uuid, v1StorageClass, options).then((request) => request(axios, basePath));
        },
        /**
         * Use to update the details of a storageClass.
         * @summary Update a storageClass.
         * @param {string} uuid Unique identifier of the cluster
         * @param {string} storageClassName Unique identifier of the storageClass
         * @param {V1StorageClass} v1StorageClass 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UpdateStorageClass(uuid: string, storageClassName: string, v1StorageClass: V1StorageClass, options?: any): AxiosPromise<Array<V1StorageClass>> {
            return localVarFp.v1UpdateStorageClass(uuid, storageClassName, v1StorageClass, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StorageClassesApi - object-oriented interface
 * @export
 * @class StorageClassesApi
 * @extends {BaseAPI}
 */
export class StorageClassesApi extends BaseAPI {
    /**
     * 
     * @summary create a Storage Class
     * @param {StorageClass} storageClass 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageClassesApi
     */
    public createStorageClass(storageClass: StorageClass, options?: AxiosRequestConfig) {
        return StorageClassesApiFp(this.configuration).createStorageClass(storageClass, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete a Storage Class
     * @param {string} clusterId The id of the cluster
     * @param {string} name filter by name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageClassesApi
     */
    public deleteStorageClass(clusterId: string, name: string, options?: AxiosRequestConfig) {
        return StorageClassesApiFp(this.configuration).deleteStorageClass(clusterId, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get a Storage Class/Classes for a given cluster
     * @param {string} clusterId The id of the cluster
     * @param {string} [name] filter by name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageClassesApi
     */
    public getStorageClasses(clusterId: string, name?: string, options?: AxiosRequestConfig) {
        return StorageClassesApiFp(this.configuration).getStorageClasses(clusterId, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use to sync cluster storage classes
     * @summary Sync cluster Storage Classes
     * @param {string} clusterId The id of the cluster
     * @param {StorageClasses} storageClasses 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageClassesApi
     */
    public syncStorageClass(clusterId: string, storageClasses: StorageClasses, options?: AxiosRequestConfig) {
        return StorageClassesApiFp(this.configuration).syncStorageClass(clusterId, storageClasses, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use to create a storageClass object in a cluster using the cluster\'s Universally Unique Identifier (UUID).
     * @summary Create a storageClass.
     * @param {string} uuid Unique identifier of the cluster
     * @param {V1StorageClass} v1StorageClass 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageClassesApi
     */
    public v1CreateStorageClass(uuid: string, v1StorageClass: V1StorageClass, options?: AxiosRequestConfig) {
        return StorageClassesApiFp(this.configuration).v1CreateStorageClass(uuid, v1StorageClass, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use to delete a storageClass by Universally Unique Identifier (UUID).
     * @summary Delete a storageClass.
     * @param {string} uuid Unique identifier of the cluster
     * @param {string} storageClassName Unique identifier of the storageClass
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageClassesApi
     */
    public v1DeleteStorageClass(uuid: string, storageClassName: string, options?: AxiosRequestConfig) {
        return StorageClassesApiFp(this.configuration).v1DeleteStorageClass(uuid, storageClassName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a list of storageClass names by Universally Unique Identifier (UUID) of the cluster.
     * @summary Get all storageClasses from a cluster.
     * @param {string} uuid Unique identifier of the cluster
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageClassesApi
     */
    public v1GetStorageClasses(uuid: string, options?: AxiosRequestConfig) {
        return StorageClassesApiFp(this.configuration).v1GetStorageClasses(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use to sync the storageClass to a cluster.
     * @summary Sync storageClass to a cluster.
     * @param {string} uuid Unique identifier of the cluster
     * @param {Array<V1StorageClass>} v1StorageClass 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageClassesApi
     */
    public v1SyncStorageClass(uuid: string, v1StorageClass: Array<V1StorageClass>, options?: AxiosRequestConfig) {
        return StorageClassesApiFp(this.configuration).v1SyncStorageClass(uuid, v1StorageClass, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use to update the details of a storageClass.
     * @summary Update a storageClass.
     * @param {string} uuid Unique identifier of the cluster
     * @param {string} storageClassName Unique identifier of the storageClass
     * @param {V1StorageClass} v1StorageClass 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageClassesApi
     */
    public v1UpdateStorageClass(uuid: string, storageClassName: string, v1StorageClass: V1StorageClass, options?: AxiosRequestConfig) {
        return StorageClassesApiFp(this.configuration).v1UpdateStorageClass(uuid, storageClassName, v1StorageClass, options).then((request) => request(this.axios, this.basePath));
    }
}


