import { ECustomCell, type IStatusColOptions, type ITableColumn } from "@/models/table.model";
import {
  EWorkloadNodeArchitecture,
  type IWorkloadListModal,
  workloadPhaseFilterOptions,
  type WorkloadServicePod,
} from "@/models/workload.model";
import {
  type Connection,
  type Datasource,
  type Environment,
  type HistoryRecord,
  type Workload,
  type WorkloadRequestResources,
  WorkloadSortFilterFields,
} from "@/swagger-models/workloads-service-client";
import { dateUtil } from "@/utils/date.util";
import { capitalizeString } from "@/utils/string.util/string.util";
import { workloadUtil } from "@/utils/workload.util";
import { formatK8sResourceMemory } from "@/utils/format.util/format.util";
import { InternalConnectionType } from "@/swagger-models/assets-service-client";
import { CLUSTER_COLUMN_FILTER_NAME, EColumnFilterType } from "@/models/filter.model";
import { isEmpty } from "@/utils/object.util";

//connections
export const allConnectionsTableColumnsMap: Record<string, ITableColumn> = {
  name: {
    name: "name",
    label: "Name",
    field: (row: Connection) => row.name,
    sortable: true,
    align: "left",
    customCell: ECustomCell.NAME_COL,
  },
  connectionType: {
    name: "connection-type",
    label: "Connection type",
    field: (row: Connection) => row.connectionType,
    format: (val: InternalConnectionType) => {
      switch (val) {
        case InternalConnectionType.ExternalUrl:
          return "External URL";
        case InternalConnectionType.NodePort:
          return "Node port";
        case InternalConnectionType.ServingPort:
          return "Serving endpoint";
        default:
          return val || "-";
      }
    },
    sortable: true,
    align: "left",
  },
  url: {
    name: "connection-url",
    label: "Address",
    field: (row: Connection) => row.url,
    sortable: true,
    align: "left",
    customCell: ECustomCell.WORKLOAD_CONNECTION_COL,
  },
  access: {
    name: "access",
    label: "Access",
    field: (row: Connection) => row,
    sortable: true,
    align: "left",
    customCell: ECustomCell.TOOL_ACCESS_COL,
    hideFilter: true,
  },
};
export const connectionsTableColumns: Array<ITableColumn> = [
  {
    ...allConnectionsTableColumnsMap.name,
    display: true,
  },
  {
    ...allConnectionsTableColumnsMap.connectionType,
    display: true,
  },
  {
    ...allConnectionsTableColumnsMap.access,
    display: true,
  },
  {
    ...allConnectionsTableColumnsMap.url,
    display: true,
  },
];

//workload events history table
const allEventHistoryColumnsMap = {
  createdAt: {
    name: "createdAt",
    label: "Date & Time",
    field: (historyRecord: HistoryRecord) => historyRecord.meta.creationTimestamp,
    align: "left",
    display: true,
    sortable: true,
    format: (createdAt: string) =>
      createdAt && dateUtil.dateAndTimeFormat(new Date(createdAt), { includeMilliseconds: true, includeSeconds: true }),
  },
  event: {
    name: "event",
    label: "Event",
    field: (historyRecord: HistoryRecord) =>
      workloadUtil.isPhaseUpdateRecordType(historyRecord) ? "Status changed" : historyRecord.spec.event?.reason,
    align: "left",
    sortable: true,
    display: true,
  },
  eventType: {
    name: "eventType",
    label: "Event type",
    field: (historyRecord: HistoryRecord) => historyRecord.spec.event?.type || "-",
    align: "left",
    sortable: true,
    display: true,
  },
  eventIssuer: {
    name: "eventIssuer",
    label: "Event issuer",
    field: (historyRecord: HistoryRecord) => historyRecord.spec.event?.source || "-",
    align: "left",
    display: true,
    sortable: true,
  },
  component: {
    name: "component",
    label: "Component",
    field: (historyRecord: HistoryRecord) => historyRecord.spec.event?.involvedObject?.kind || "",
    align: "left",
    display: true,
    sortable: true,
  },
  details: {
    name: "details",
    label: "Details",
    field: (historyRecord: HistoryRecord) =>
      workloadUtil.isPhaseUpdateRecordType(historyRecord)
        ? historyRecord.spec.phaseUpdate?.phase
        : historyRecord.spec.event?.message,
    align: "left",
    display: true,
    sortable: true,
  },
} as Record<string, ITableColumn>;

export const eventHistoryColumns: Array<ITableColumn> = [
  { ...allEventHistoryColumnsMap.createdAt, display: true },
  { ...allEventHistoryColumnsMap.event, display: true },
  { ...allEventHistoryColumnsMap.eventType, display: true },
  { ...allEventHistoryColumnsMap.eventIssuer, display: true },
  { ...allEventHistoryColumnsMap.component, display: true },
  { ...allEventHistoryColumnsMap.details, display: true },
];

//pods modal
export const allWorkloadPodsModalColumnsMap: Record<string, ITableColumn> = {
  name: {
    name: "name",
    label: "Pod",
    field: (pod: WorkloadServicePod) => pod.name,
    sortable: true,
    align: "left",
  },
  status: {
    name: "status",
    label: "Status",
    field: (pod: WorkloadServicePod) => pod,
    sortable: true,
    align: "left",
    format: (pod: WorkloadServicePod): IStatusColOptions | string => workloadUtil.getPodStatusColOptions(pod),
    sort: (podA: WorkloadServicePod, podB: WorkloadServicePod): number => podA.k8sPhase.localeCompare(podB.k8sPhase),
    exportFormat: (pod: WorkloadServicePod) => pod.k8sPhase || "NA",
    filterKey: "status",
    customCell: ECustomCell.STATUS_COL,
  },
  gpuComputeAllocation: {
    name: "gpuComputeAllocation",
    label: "GPU compute allocation",
    field: (pod: WorkloadServicePod): number | null => {
      return pod.allocatedResources?.gpu || null;
    },
    format: (gpuCompute: number | null): number | string => (gpuCompute || gpuCompute === 0 ? gpuCompute : "-"),
    sortable: true,
    align: "left",
  },
  gpuMemoryAllocation: {
    name: "gpuMemoryAllocation",
    label: "GPU memory allocation",
    field: (pod: WorkloadServicePod): string | null | undefined => {
      return pod.allocatedResources?.gpuMemory;
    },
    format: (gpuMemory: string | null): string => (gpuMemory ? formatK8sResourceMemory(gpuMemory) : "-"),
    sortable: true,
    align: "left",
  },
  nodeName: {
    name: "nodeName",
    label: "Node",
    field: (pod: WorkloadServicePod) => pod.nodeName || "-",
    sortable: true,
    align: "left",
  },
  projectName: {
    name: "projectName",
    label: "Project",
    field: (pod: WorkloadServicePod) => (pod.projectName ? [pod.projectName] : "-"),
    sortable: true,
    align: "left",
    customCell: ECustomCell.LIST_COL,
    customCellEvent: { emitName: "project-clicked" },
  },
  workloadName: {
    name: "workloadName",
    label: "Workload",
    field: (pod: WorkloadServicePod) => (pod.workloadName ? [pod.workloadName] : "-"),
    sortable: true,
    align: "left",
    customCell: ECustomCell.LIST_COL,
    customCellEvent: { emitName: "workload-clicked" },
  },
  nodePools: {
    name: WorkloadSortFilterFields.Nodepool,
    label: "Node pool",
    field: (pod: WorkloadServicePod) => pod.currentNodePool || "-",
    sortable: true,
    align: "left",
  },
  image: {
    name: "image",
    label: "Image",
    field: (pod: WorkloadServicePod) => pod.containers[0].image,
    sortable: true,
    align: "left",
  },
  ip: {
    name: "ip",
    label: "IP",
    field: (pod: WorkloadServicePod) => pod.ip || "-",
    sortable: true,
    align: "left",
  },
  created: {
    name: "created",
    label: "Creation time",
    field: (pod: WorkloadServicePod) => pod.createdAt,
    format: (createdAt: string): string => (!createdAt ? "-" : dateUtil.dateAndTimeFormat(new Date(createdAt))),
    sortable: true,
    align: "left",
  },
};

export const workloadPodsModalTableColumns: Array<ITableColumn> = [
  { ...allWorkloadPodsModalColumnsMap.name, display: true },
  { ...allWorkloadPodsModalColumnsMap.status, display: true },
  { ...allWorkloadPodsModalColumnsMap.nodeName, display: true },
  { ...allWorkloadPodsModalColumnsMap.nodePools, display: true },
  { ...allWorkloadPodsModalColumnsMap.gpuComputeAllocation, display: true },
  { ...allWorkloadPodsModalColumnsMap.gpuMemoryAllocation, display: true },
  { ...allWorkloadPodsModalColumnsMap.image, display: true },
  { ...allWorkloadPodsModalColumnsMap.ip, display: true },
  { ...allWorkloadPodsModalColumnsMap.created, display: true },
];

export const nodePodsModalTableColumns: Array<ITableColumn> = [
  { ...allWorkloadPodsModalColumnsMap.name, display: true },
  { ...allWorkloadPodsModalColumnsMap.status, display: true },
  { ...allWorkloadPodsModalColumnsMap.projectName, display: true },
  { ...allWorkloadPodsModalColumnsMap.workloadName, display: true },
  { ...allWorkloadPodsModalColumnsMap.image, display: true },
  { ...allWorkloadPodsModalColumnsMap.ip, display: true },
  { ...allWorkloadPodsModalColumnsMap.created, display: true },
];

export const workloadPodsModalDependentColumns = {
  // display if there is more than one node pool
  nodePools: new Set([allWorkloadPodsModalColumnsMap.nodePools.name]),
  // display for 2.19 +
  ip: new Set([allWorkloadPodsModalColumnsMap.ip.name]),
};

const workloadTypeMap: Record<string, string> = {
  Training: "Training",
  Interactive: "Workspace",
  Distributed: "Training", // we display distributed workloads as training
  DistributedTraining: "Training",
  TFJob: "Training",
};

//workloads table data
export const allWorkloadColumnsMap: Record<string, ITableColumn> = {
  name: {
    name: WorkloadSortFilterFields.Name,
    label: "Workload",
    field: (workload: Workload) => workload.name,
    sortable: true,
    align: "left",
    customCell: ECustomCell.NAME_COL,
    filter: {
      type: EColumnFilterType.FreeText,
    },
  },
  type: {
    name: WorkloadSortFilterFields.Type,
    label: "Type",
    field: (workload: Workload) => workload.type,
    format: (workloadType: string) => workloadTypeMap[workloadType] || capitalizeString(workloadType),
    sortable: true,
    align: "left",
    filter: {
      type: EColumnFilterType.FreeText,
    },
  },
  preemptible: {
    name: "preemptible",
    label: "Preemptible",
    field: (workload: Workload): boolean | null | undefined => workload.preemptible,
    format: (preemptible: boolean | null | undefined) => {
      if (typeof preemptible === "boolean") {
        return preemptible ? "Yes" : "No";
      }
      return "-";
    },
    sortable: false,
    align: "left",
    hideFilter: true,
  },
  status: {
    name: WorkloadSortFilterFields.Phase,
    label: "Status",
    field: (workload: Workload) => workload,
    sortable: true,
    align: "left",
    customCell: ECustomCell.STATUS_COL,
    format: (workload: Workload): IStatusColOptions | string =>
      workloadUtil.getWorkloadStatusColOptions(workload, workload.phaseMessage),
    sort: (workloadA: Workload, workloadB: Workload): number => workloadA.phase.localeCompare(workloadB.phase),
    exportFormat: (workload: Workload) => workload.phase || "NA",
    filter: {
      type: EColumnFilterType.EnumBased,
      selectOptions: workloadPhaseFilterOptions,
    },
  },
  submittedBy: {
    name: WorkloadSortFilterFields.SubmittedBy,
    label: "Created by",
    field: (workload: Workload) => workload.submittedBy || "-",
    sortable: true,
    align: "left",
    filter: {
      type: EColumnFilterType.FreeText,
    },
  },
  departmentName: {
    name: WorkloadSortFilterFields.DepartmentName,
    label: "Department",
    field: (workload: Workload) => workload.departmentName || "-",
    sortable: true,
    align: "left",
    filter: {
      type: EColumnFilterType.FreeText,
    },
  },
  project: {
    name: WorkloadSortFilterFields.ProjectName,
    label: "Project",
    field: (workload: Workload) => [workload.projectName],
    sortable: true,
    align: "left",
    filter: {
      type: EColumnFilterType.FreeText,
    },
    customCell: ECustomCell.LIST_COL,
    customCellEvent: { emitName: "project-clicked" },
  },
  nodePools: {
    name: WorkloadSortFilterFields.Nodepool,
    label: "Node pool",
    field: (workload: Workload): string[] => {
      if (workload?.currentNodePools?.length) {
        return workload.currentNodePools;
      }
      return [];
    },
    format: (currentNodePools: string[]): string => (currentNodePools.length > 0 ? currentNodePools.join(",") : "-"),
    sortable: true,
    align: "left",
    filter: {
      type: EColumnFilterType.FreeText,
    },
  },
  runningPodsVsRequestedPods: {
    name: "runningPodsVsRequestedPods",
    label: "Running / requested pods",
    field: (workload: Workload) => workload,
    format: (workload: Workload): string[] => [workloadUtil.getRunningVsRequestedDisplayedValue(workload)],
    sortable: false,
    align: "left",
    customCell: ECustomCell.LIST_COL,
    customCellEvent: { emitName: "pods-clicked" },
    hideFilter: true,
  },
  runningPodsVsRequestedPodsForModal: {
    name: "runningPodsVsRequestedPods",
    label: "Running / requested pods",
    field: (workload: Workload) => workload,
    format: (workload: Workload): string => workloadUtil.getRunningVsRequestedDisplayedValue(workload),
    sortable: false,
    align: "left",
    customCell: ECustomCell.LIST_COL,
    hideFilter: true,
  },
  creationTime: {
    name: WorkloadSortFilterFields.CreatedAt,
    label: "Creation time",
    field: (workload: Workload) => workload.createdAt,
    format: (createdAt: string): string => {
      return !createdAt ? "-" : dateUtil.dateAndTimeFormat(new Date(createdAt));
    },
    sortable: true,
    align: "left",
    filter: {
      type: EColumnFilterType.Date,
    },
  },
  completionTime: {
    name: WorkloadSortFilterFields.CompletedAt,
    label: "Completion time",
    field: (workload: Workload) => workload.completedAt,
    format: (completedAt: string): string => {
      return !completedAt ? "-" : dateUtil.dateFormat(new Date(completedAt), "dd/MM/yyyy HH:mm");
    },
    sortable: true,
    align: "left",
    filter: {
      type: EColumnFilterType.Date,
    },
  },
  dataSource: {
    name: "data-sources",
    label: "Data source(s)",
    field: (workload: Workload) => workload.datasources,
    sortable: false,
    hideFilter: true,
    align: "left",
    format: (dataSources: Datasource[]): Array<string> => {
      if (!dataSources?.length) return [];
      return dataSources.map((datasource: Datasource) => datasource.name);
    },
    customCell: ECustomCell.LIST_COL,
    customCellEvent: { emitName: "data-source-clicked" },
  },
  environment: {
    name: "environment",
    label: "Environment",
    field: (workload: Workload) => workload.environments,
    sortable: false,
    align: "left",
    format: (environments: Environment[]) => {
      if (!environments?.length) return ["-"];
      return environments.map((env: Environment) => env.name);
    },
    customCell: ECustomCell.STRINGS_LIST_COL,
    hideFilter: true,
  },
  multiNode: {
    name: "multi-node",
    label: "Workload architecture",
    field: (row: Workload) => row.distributedFramework,
    sortable: false,
    align: "left",
    format: (distributedFramework: string | undefined) =>
      distributedFramework ? EWorkloadNodeArchitecture.Distributed : EWorkloadNodeArchitecture.Standard,
    hideFilter: true,
  },
  requestedGPU: {
    name: "requestedGPU",
    label: "GPU compute request",
    field: (workload: Workload): string | number | null | undefined => {
      const requestedResources: WorkloadRequestResources | undefined | null = !isEmpty(workload.podsRequestedResources)
        ? workload.podsRequestedResources
        : workload.workloadRequestedResources;
      return requestedResources?.gpu?.request || "-";
    },
    sortable: false,
    align: "left",
    hideFilter: true,
  },
  allocatedGPU: {
    name: WorkloadSortFilterFields.AllocatedGpu,
    label: "GPU compute allocation",
    field: (workload: Workload) => {
      return workload.allocatedResources?.gpu || "-";
    },
    sortable: true,
    align: "left",
    filter: {
      type: EColumnFilterType.Numeric,
    },
  },
  requestedGPUMemory: {
    name: "requestedGPUMemory",
    label: "GPU memory request",
    field: (workload: Workload): string | number | null | undefined => {
      const requestedResources: WorkloadRequestResources | undefined | null = !isEmpty(workload.podsRequestedResources)
        ? workload.podsRequestedResources
        : workload.workloadRequestedResources;
      return requestedResources?.gpuMemory?.request || "-";
    },
    format: (gpuMemory: string | null | undefined): string => (gpuMemory ? formatK8sResourceMemory(gpuMemory) : "-"),
    sortable: false,
    align: "left",
    hideFilter: true,
  },
  allocatedGPUMemory: {
    name: "allocatedGPUMemory",
    label: "GPU memory allocation",
    field: (workload: Workload): string | null | undefined => {
      return workload.allocatedResources?.gpuMemory;
    },
    format: (gpuMemory: string | null | undefined): string => (gpuMemory ? formatK8sResourceMemory(gpuMemory) : "-"),
    sortable: false,
    align: "left",
    hideFilter: true,
  },
  requestedCPUMemory: {
    name: "requestedCPUMemory",
    label: "CPU memory request",
    field: (workload: Workload): string | null | undefined => {
      const requestedResources: WorkloadRequestResources | undefined | null = !isEmpty(workload.podsRequestedResources)
        ? workload.podsRequestedResources
        : workload.workloadRequestedResources;
      return requestedResources?.cpuMemory?.request || "-";
    },
    format: (cpuMemory: string | null | undefined): string => (cpuMemory ? formatK8sResourceMemory(cpuMemory) : "-"),
    sortable: false,
    align: "left",
    hideFilter: true,
  },
  allocatedCPUMemory: {
    name: "allocatedCPUMemory",
    label: "CPU memory allocation",
    field: (workload: Workload): string | null | undefined => {
      return workload.allocatedResources?.cpuMemory;
    },
    format: (cpuMemory: string | null | undefined): string => (cpuMemory ? formatK8sResourceMemory(cpuMemory) : "-"),
    sortable: false,
    align: "left",
    hideFilter: true,
  },
  requestedCPUCompute: {
    name: "requestedCPUCompute",
    label: "CPU compute request",
    field: (workload: Workload): string | number | null | undefined => {
      const requestedResources: WorkloadRequestResources | undefined | null = !isEmpty(workload.podsRequestedResources)
        ? workload.podsRequestedResources
        : workload.workloadRequestedResources;
      return requestedResources?.cpu?.request || "-";
    },
    sortable: false,
    align: "left",
    hideFilter: true,
  },
  allocatedCPUCompute: {
    name: "allocatedCPUCompute",
    label: "CPU compute allocation",
    field: (workload: Workload): number | string => workload.allocatedResources?.cpu || "-",
    sortable: false,
    align: "left",
    hideFilter: true,
  },
  connections: {
    name: "connections",
    label: "Connection(s)",
    field: (workload: Workload) => workloadUtil.getWorkloadConnectionsForGrid(workload),
    sortable: false,
    align: "left",
    format: (connections: Array<Connection>): number[] | string => {
      const amount = connections?.length;
      return amount ? [amount] : "-";
    },
    customCell: ECustomCell.LIST_COL,
    customCellEvent: { emitName: "connections-clicked" },
    hideFilter: true,
  },
  allocatedGpu: {
    name: "allocatedGpu",
    label: "Allocated GPU compute",
    field: (workload: Workload) => {
      return workload.allocatedResources?.gpu || "-";
    },
    sortable: false,
    align: "left",
    hideFilter: true,
  },
  allocatedGpuMemory: {
    name: "allocatedGpuMemory",
    label: "Allocated GPU memory ",
    field: (workload: Workload): string | undefined | null => {
      return workload.allocatedResources?.gpuMemory;
    },
    format: (gpuMemory: string | undefined | null): string => (gpuMemory ? formatK8sResourceMemory(gpuMemory) : "-"),
    sortable: false,
    align: "left",
    hideFilter: true,
  },
  allocatedCpu: {
    name: "allocatedCpu",
    label: "Allocated CPU compute (Cores)",
    field: (workload: Workload) => workload.allocatedResources?.cpu || "-",
    sortable: false,
    align: "left",
    hideFilter: true,
  },
  allocatedCpuMemory: {
    name: "allocatedCpuMemory",
    label: "Allocated CPU memory",
    field: (workload: Workload): string | undefined | null => {
      return workload.allocatedResources?.cpuMemory;
    },
    format: (cpuMemory: string | undefined | null): string => (cpuMemory ? formatK8sResourceMemory(cpuMemory) : "-"),
    sortable: false,
    align: "left",
    hideFilter: true,
  },
  cluster: {
    name: CLUSTER_COLUMN_FILTER_NAME,
    label: "Cluster",
    field: (workload: Workload): string | undefined => workload.clusterId,
    sortable: false,
    align: "left",
    customCell: ECustomCell.CLUSTER_ID_TO_NAME_COL,
    hideFilter: true,
  },
};

export const workloadIndexColumns: Array<ITableColumn> = [
  { ...allWorkloadColumnsMap.name, display: true, mandatory: true },
  { ...allWorkloadColumnsMap.type, display: true },
  { ...allWorkloadColumnsMap.preemptible, display: false },
  { ...allWorkloadColumnsMap.status, display: true },
  { ...allWorkloadColumnsMap.project, display: true },
  { ...allWorkloadColumnsMap.departmentName, display: true },
  { ...allWorkloadColumnsMap.submittedBy, display: false },
  { ...allWorkloadColumnsMap.nodePools, display: true },
  { ...allWorkloadColumnsMap.runningPodsVsRequestedPods, display: true },
  { ...allWorkloadColumnsMap.creationTime, display: false },
  { ...allWorkloadColumnsMap.completionTime, display: false },
  { ...allWorkloadColumnsMap.connections, display: false },
  { ...allWorkloadColumnsMap.dataSource, display: false },
  { ...allWorkloadColumnsMap.environment, display: false },
  { ...allWorkloadColumnsMap.multiNode, display: false },
  { ...allWorkloadColumnsMap.requestedGPU, display: true },
  { ...allWorkloadColumnsMap.allocatedGPU, display: true },
  { ...allWorkloadColumnsMap.requestedGPUMemory, display: true },
  { ...allWorkloadColumnsMap.allocatedGPUMemory, display: true },
  { ...allWorkloadColumnsMap.requestedCPUCompute, display: false },
  { ...allWorkloadColumnsMap.allocatedCPUCompute, display: false },
  { ...allWorkloadColumnsMap.requestedCPUMemory, display: false },
  { ...allWorkloadColumnsMap.allocatedCPUMemory, display: false },
  { ...allWorkloadColumnsMap.cluster, display: true },
];

//workloads list modal
export const workloadListModalColumns: Array<ITableColumn> = [
  { ...allWorkloadColumnsMap.name, display: true },
  { ...allWorkloadColumnsMap.type, display: true },
  { ...allWorkloadColumnsMap.status, display: true },
  { ...allWorkloadColumnsMap.submittedBy, display: true },
  { ...allWorkloadColumnsMap.runningPodsVsRequestedPodsForModal, display: true },
  { ...allWorkloadColumnsMap.creationTime, display: true },
  { ...allWorkloadColumnsMap.createdAt, display: true },
  { ...allWorkloadColumnsMap.requestedGPU, display: true },
  { ...allWorkloadColumnsMap.requestedGPUMemory, display: true },
  { ...allWorkloadColumnsMap.requestedCPUMemory, display: true },
  { ...allWorkloadColumnsMap.requestedCPUCompute, display: true },
];

export const workloadDependentColumns = {
  // display only for versions that support distributed workloads
  multiNode: new Set([allWorkloadColumnsMap.multiNode.name]),
  // display if department enabled
  department: new Set([allWorkloadColumnsMap.departmentName.name]),
  // display if there is more than one node pool and on active workloads view only
  nodePools: new Set([allWorkloadColumnsMap.nodePools.name]),
  hiddenOnDeletedView: new Set([
    allWorkloadColumnsMap.allocatedGPU.name,
    allWorkloadColumnsMap.allocatedGPUMemory.name,
    allWorkloadColumnsMap.allocatedCpuMemory.name,
    allWorkloadColumnsMap.allocatedCpu.name,
    allWorkloadColumnsMap.allocatedCPUCompute.name,
    allWorkloadColumnsMap.allocatedCPUMemory.name,
    allWorkloadColumnsMap.nodePools.name,
    allWorkloadColumnsMap.runningPodsVsRequestedPods.name,
    allWorkloadColumnsMap.status.name,
  ]),
};

export const allWorkloadMiniTableColumnsMap: Record<string, ITableColumn> = {
  name: {
    name: "name",
    label: "Workload",
    field: (row: IWorkloadListModal) => row.name,
    sortable: true,
    align: "left",
    customCell: ECustomCell.NAME_COL,
  },
  type: {
    name: "type",
    label: "Type",
    field: (row: IWorkloadListModal) => row.type,
    sortable: true,
    align: "left",
  },
  status: {
    name: "workload-status",
    label: "Status",
    field: (row: IWorkloadListModal) => row.status,
    sortable: true,
    align: "left",
    customCell: ECustomCell.STATUS_COL,
    sort: (statusA: IStatusColOptions, statusB: IStatusColOptions): number => {
      if (statusA.status && statusB.status) {
        return statusA.status.localeCompare(statusB.status);
      }
      return -1;
    },
    exportFormat: (status: IStatusColOptions) => status.status || "NA",
    filterKey: "status",
  },
};

export const workloadMiniTableColumns: Array<ITableColumn> = [
  { ...allWorkloadMiniTableColumnsMap.name, display: true },
  { ...allWorkloadMiniTableColumnsMap.type, display: true },
  { ...allWorkloadMiniTableColumnsMap.status, display: true },
];
